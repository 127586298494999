import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { useNavigate } from 'react-router-dom';
import HeaderUserMenuPresenter from '../presenters/HeaderUserMenuPresenter';
import { useLogout } from '../../auth/hooks/useLogout';
import { getJoinMemberships, hasAdminAuth, isSystemSupervisor } from '../../auth/auth.type';
import { useTranslation } from 'react-i18next';

const HeaderUserMenuContainer: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const logout = useLogout();
  // ユーザーメニューの表示制御
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  // ログインしていない場合は何も表示しない
  if (!loginUser) {
    return <></>
  }

  const closeMenuAndGo = (path_or_url: string, otherSite: boolean) => {
    return () => {
      handleMenuClose();
      if (otherSite) {
        window.open(path_or_url, '_blank');
      } else {
        navigate(path_or_url);
      }
    };
  }

  const handleLogout = () => {
    logout();
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const menuItems = [];
  // t:個人設定
  menuItems.push({ label: t("header:personalSettings"), onClick: closeMenuAndGo('/setting', false) });
  if (hasAdminAuth(loginUser)) {
    // t:管理メニュー
    menuItems.push({ label: t("header:adminMenu"), onClick: closeMenuAndGo('/admin', false) });
  }
  if (getJoinMemberships(loginUser).length > 1) {
    // t:チーム切り替え
    menuItems.push({ label: t("header:switchTeam"), onClick: closeMenuAndGo('/teams/select', false) });
  }
  if (isSystemSupervisor(loginUser)) {
    // t:＜＜システム運営＞＞
    menuItems.push({ label: t("header:systemOperation"), onClick: closeMenuAndGo('/supervisor', false) });
  }
  // t:ユーザーマニュアル
  menuItems.push({ label: t("header:userManual"), onClick: closeMenuAndGo(t("urls:userManual"), true) });
  // t:ログアウト
  menuItems.push({ label: t("header:logout"), onClick: handleLogout });


  return (
    <HeaderUserMenuPresenter
      loginUser={loginUser}
      isMenuOpen={isMenuOpen}
      anchorEl={anchorEl}
      menuItems={menuItems}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
    />
  );
};

export default HeaderUserMenuContainer;
