import { DlpBlockResult, ThreadDlpReplace } from "../dlp/dlp.type";
import { FileUpload } from "../file/file.type";
import { QuickPromptVersionWithQP } from "../quickPrompt/quickPrompt.type";
import { QuotaCheckResult, TeamUsage, ThreadUsage } from "../usage/usage.type";
import { AIMessageStatus, MessageType, UserMessageType } from "./thread.constant";


// ----------------------------------------------
// リクエスト
// ----------------------------------------------

export enum ThreadCreateType {
  NORMAL = "normal",
  QUICK_PROMPT = "quick_prompt"
}

export type CreateThreadRequest = {
  type: ThreadCreateType;
  normalDetail?: {
    message: object;
  };
  quickPromptDetail?: {
    versionId: string;
    formInput: object;
  };
  skipDlpBlock: boolean;
  fileUploadId?: string;
  isTestMode?: boolean;
}

export type PostMessageRequest = CreateThreadRequest & {
  parentNodeId?: string;
}

// ----------------------------------------------
// レスポンス
// ----------------------------------------------

export type Thread = {
  id: string;
  title: string;
  messageNodes: MessageNode[];
  currentMessageNode: {
    messageNodeId: string;
  };
  dlpReplaces: ThreadDlpReplace[];
  isTestMode: boolean;
  createdAt: string;
  updatedAt: string;
};

export type ThreadWithUsage = Thread & {
  usage: ThreadUsage;
};

export type MessageNode = {
  id: string;
  type: MessageType;
  path: string[];
  aiMessages: AIMessage[];
  userMessage: UserMessage | null;
  createdAt: string;
  updatedAt: string;
};

export type AIMessage = {
  id: string;
  status: AIMessageStatus;
  body: string;
  version: AIVersion;
  failures: AIMessageFailure[];
  isContinuable: boolean;
  createdAt: string;
  updatedAt: string;
};

export type AIMessageFailure = {
  code: string;
  canRetry: boolean;
  createdAt: string;
}

export type UserMessage = {
  id: string;
  type: UserMessageType;
  body: object;
  isReplaced: boolean;
  sendPrompt: string;
  mentions: Mention[];
  quickPromptVersion?: QuickPromptVersionWithQP;
  fileUploads: FileUpload[];
};

export type Mention = {
  order: number;
  aiModel: AIModel;
};

export type AIVersion = {
  id: string;
  name: string;
  code: string;
  apiModelName: string;
  isLatest: boolean;
  aiModel: AIModel;
};

export type AIModel = {
  id: string;
  name: string;
  code: string;
  mention: string;
  isDefault: boolean;
  aiBrand?: AIBrand;
};

export type AIBrand = {
  id: string;
  name: string;
  code: string;
};



// ----------------------------------------------
// レスポンス (SSE用)
// ----------------------------------------------

export type SseEventUpdateThreadTitle = {
  thread: ThreadWithUsage;
}

export type SseEventMessageNodesAdded = {
  userMessageNode: MessageNode;
  aiMessageNode: MessageNode;
  dlpReplaces: ThreadDlpReplace[];
  quotaCheckResult: QuotaCheckResult;
  newTeamUsage: TeamUsage;
};

export type SseEventThreadCreated = {
  thread: ThreadWithUsage;
  messageResult: SseEventMessageNodesAdded;
};

export type SseEventUpdateMessageStatus = {
  aiMessage: AIMessage;
  newThreadUsage?: ThreadUsage;
  newTeamUsage?: TeamUsage;
};

export type SseEventAddMessageToAIMessage = {
  id: string;
  appendMessage: string
}

export type SseEventBlockedByDlp = {
  result: DlpBlockResult;
  userInput: string;
};

export type SseEventQuotaExceeded = {
  quotaCheckResult: QuotaCheckResult;
};


export type SeeEventMaxDepthOver = {
  currentDepth: number;
  maxDepth: number;
};
