import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import { Message, Upgrade, Approval, History, EditNote, Person, Block, Security, Group, TableChart, HelpOutline, Timeline, UploadFile} from '@mui/icons-material';
import { LeftSidebarSubTitle } from '@/components/ui/StyledComponents';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentMembership, getCurrentSubscription, hasSuperAdminAuth } from '../../auth/auth.type';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;

  &.active {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;
const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;

  &.active {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

const StyledListItemButton = styled(ListItemButton)`
  padding-left: 16px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 40px;
`;

const StyledListItemText = styled(ListItemText)`
  margin: 0;
  & .MuiListItemText-primary {
    font-size: 0.875rem;
  }
`;

const SubTitle: React.FC<{ name: string, isVerified: boolean }> = ({ name, isVerified }) => {
  return (
    <LeftSidebarSubTitle sx={{ display: 'flex', alignItems: 'center' }}>
      {name}
      {isVerified &&
        <VerifiedUserIcon fontSize="inherit" sx={{ ml: 0.5, mb: '2px', color: 'green' }} />
      }
    </LeftSidebarSubTitle>
  );
}
const ListItem: React.FC<{ name: string, linkTo: string, icon: React.ReactNode, onItemClick: () => void }> = ({ name, linkTo, icon, onItemClick }) => {
  return (
    <>
      <StyledNavLink to={linkTo} onClick={onItemClick}>
        <StyledListItemButton>
          <StyledListItemIcon>
            {icon}
          </StyledListItemIcon>
          <StyledListItemText primary={name} />
        </StyledListItemButton>
      </StyledNavLink>
    </>
  );
};

const AdminLeftSidebar: React.FC<{ onItemClick: () => void }> = ({ onItemClick }) => {
  const { t } = useTranslation();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const currentSub = getCurrentSubscription(loginUser);
  const membership = getCurrentMembership(loginUser)
  if (!loginUser || !currentSub || !membership) {
    return;
  }
  const quota = membership.team.quota;

  return (
    <List component="div" disablePadding>
      { hasSuperAdminAuth(loginUser) &&
        <>
          <SubTitle
            // t:チーム
            name={t("admin:sidebar.team.categoryTitle")}
            isVerified={true} />
          <ListItem
            icon={<EditNote />}
            // t:チーム基本情報
            name={t("admin:sidebar.team.teamBasicInfo")}
            linkTo="/admin/team"
            onItemClick={onItemClick} />
          <ListItem
            icon={<SettingsIcon />}
            // t:機能設定
            name={t("admin:sidebar.team.featureSettings")}
            linkTo="/admin/team/config"
            onItemClick={onItemClick} />
        </>
      }

      <SubTitle
        // t:メンバー・グループ
        name={t("admin:sidebar.membersAndGroups.categoryTitle")}
        isVerified={false} />
      <ListItem
        icon={<Person />}
        // t:メンバー管理
        name={t("admin:sidebar.membersAndGroups.memberManagement")}
        linkTo="/admin/memberships"
        onItemClick={onItemClick} />
      <ListItem
        icon={<Group />}
        // t:グループ管理
        name={t("admin:sidebar.membersAndGroups.groupManagement")}
        linkTo="/admin/membership-groups"
        onItemClick={onItemClick} />

      { hasSuperAdminAuth(loginUser) && <>
          <SubTitle
            // t:契約
            name={t("admin:sidebar.contract.categoryTitle")}
            isVerified={true} />
          <ListItem
            icon={<Approval />}
            // t:契約内容
            name={t("admin:sidebar.contract.contractDetails")}
            linkTo="/admin/subscription"
            onItemClick={onItemClick} />
          <ListItem
            icon={<Upgrade />}
            // t:アップグレード
            name={t("admin:sidebar.contract.upgrade")}
            linkTo="/admin/subscription/upgrade-contact"
            onItemClick={onItemClick} />
        </>
      }

      {
        hasSuperAdminAuth(loginUser) && <>
          <SubTitle
            // t:利用状況
            name={t("admin:sidebar.usage.categoryTitle")}
            isVerified={true} />
          <ListItem
            icon={<TableChart />}
            // t:最新の利用状況
            name={t("admin:sidebar.usage.latestUsage")}
            linkTo="/admin/usage/latest-statistics"
            onItemClick={onItemClick} />
          <ListItem
            icon={<Timeline />}
            // t:集計レポート
            name={t("admin:sidebar.usage.reports")}
            linkTo="/admin/usage/reports"
            onItemClick={onItemClick} />
        </>
      }

      {
        hasSuperAdminAuth(loginUser) && quota.vaultEnabled && <>
          <SubTitle
            // t:Vault
            name={t("admin:sidebar.vault.categoryTitle")}
            isVerified={true} />
          <ListItem
            icon={<History />}
            // t:利用履歴
            name={t("admin:sidebar.vault.usageHistory")}
            linkTo="/admin/vault/usages"
            onItemClick={onItemClick} />
          <ListItem
            icon={<Message />}
            // メッセージ履歴
            name={t("admin:sidebar.vault.messageHistory")}
            linkTo="/admin/vault/messages"
            onItemClick={onItemClick} />
          <ListItem
            icon={<UploadFile />}
            // ファイルアップロード履歴
            name={t("admin:sidebar.vault.fileUploadHistory")}
            linkTo="/admin/vault/files"
            onItemClick={onItemClick} />
        </>
      }

      <SubTitle
        // セキュリティ
        name={t("admin:sidebar.security.categoryTitle")}
        isVerified={false} />
      <ListItem
        icon={<Block />}
        // 機密ワードブロッカー
        name={t("admin:sidebar.security.sensitiveWordBlocker")}
        linkTo="/admin/dlp/expression-configs"
        onItemClick={onItemClick} />
      <ListItem
        icon={<Security />}
        // DLP設定
        name={t("admin:sidebar.security.dlpSettings")}
        linkTo="/admin/dlp/info-type-configs"
        onItemClick={onItemClick} />


      <SubTitle
        // その他
        name={t("admin:sidebar.other.categoryTitle")}
        isVerified={false} />
      <StyledLink
        href={t("urls:adminManual")}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => {
          e.preventDefault();
          window.open(t("urls:adminManual"), '_blank', 'noopener,noreferrer');
          onItemClick();
        }}
      >
        <StyledListItemButton>
          <StyledListItemIcon>
            <HelpOutline />
          </StyledListItemIcon>
          {/* 管理者マニュアル */}
          <StyledListItemText primary={t("admin:sidebar.other.adminManual")} />
        </StyledListItemButton>
      </StyledLink>
    </List>
  );
};

export default AdminLeftSidebar;
