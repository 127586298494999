import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import QpListPresenter from '../presenters/QpListPresenter';
import { AppDispatch, RootState } from '@/store';
import { getAllQuickPrompts, QpManagement, QuickPromptSetWithQpList } from '../quickPrompt.type';
import apiAxios from '@/lib/axios';
import { setQuickPromptSets, setBookmarkedQuickPromptIds, addBookmark, removeBookmark, setQpManagement, updateQpManagement } from '../quickPrompt.slice';
import { captureException } from '@sentry/react';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import { getCurrentMembership } from '../../auth/auth.type';
import { useTranslation } from 'react-i18next';
import { convertStrToLanguage } from '../../setting/setting.utils';
import { FAVORITE_QP_SET_ID, QpAuthorityType, QuickPromptSetType } from '../quickPrompt.constant';
import { sortQpSetByQpManagementOrder } from '../quickPrompt.utils';

const QpListContainer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    quickPromptSets,
    bookmarkedQuickPromptIds,
    qpManagement,
  } = useSelector((state: RootState) => state.quickPrompt, shallowEqual);
  const { setUnknownErrorModal } = useErrorModal();
  const [expandedSets, setExpandedSets] = useState<{ [key: string]: boolean }>(() => {
    const storedExpandedSets = localStorage.getItem('expandedSets');
    return storedExpandedSets ? JSON.parse(storedExpandedSets) : {'0': true};
  });
  const loginUser = useSelector((state: RootState) => state.auth.loginUser, shallowEqual);
  const currentMembership = getCurrentMembership(loginUser);
  const { t, i18n } = useTranslation();
  const lang = convertStrToLanguage(i18n.language);

  useEffect(() => {
    // チームに所属していない場合はコールしない
    if (!currentMembership) {
      return;
    }

    const fetchData = async () => {
      if (quickPromptSets.length > 0 && bookmarkedQuickPromptIds.length > 0) return;

      try {
        const [
          quickPromptSetsResponse,
          bookmarkedIdsResponse,
          qpManagementResponse
        ] = await Promise.all([
          apiAxios.get<QuickPromptSetWithQpList[]>('/quick-prompt-sets'),
          apiAxios.get<string[]>('/bookmarked-quick-prompt-ids'),
          apiAxios.get<QpManagement>('/ui/qp-managements')
        ]);

        dispatch(setQuickPromptSets(quickPromptSetsResponse.data));
        dispatch(setBookmarkedQuickPromptIds(bookmarkedIdsResponse.data));
        dispatch(setQpManagement(qpManagementResponse.data));

        // ローカルストレージから開閉状態を取得し、初期状態として設定
        const storedExpandedSets = localStorage.getItem('expandedSets');
        if (storedExpandedSets) {
          setExpandedSets(JSON.parse(storedExpandedSets));
        }
      } catch (error) {
        captureException(error);
        setUnknownErrorModal();
        console.error('Failed to fetch quick prompt sets:', error);
      }
    };

    fetchData();
  }, [dispatch, currentMembership, setUnknownErrorModal]);

  const handleToggleSet = useCallback((setId: string, forceValue: boolean | undefined = undefined) => {
    setExpandedSets((prevExpandedSets) => {
      const newExpandedSets = {
        ...prevExpandedSets,
        [setId]: forceValue !== undefined ? forceValue : !prevExpandedSets[setId],
      };
      // 開閉状態をローカルストレージに保存
      localStorage.setItem('expandedSets', JSON.stringify(newExpandedSets));
      return newExpandedSets;
    });
  }, []);

  const handleAddBookmark = useCallback(async (quickPromptId: string) => {
    try {
      handleToggleSet("0", true); // お気に入りを必ず開く
      await dispatch(addBookmark(quickPromptId));
    } catch (error) {
      console.error('ブックマークの追加に失敗しました:', error);
    }
  }, [dispatch, handleToggleSet]);

  const handleRemoveBookmark = useCallback(async (quickPromptId: string) => {
    try {
      await dispatch(removeBookmark(quickPromptId));
    } catch (error) {
      console.error('ブックマークの削除に失敗しました:', error);
    }
  }, [dispatch]);

  const handleUpdateSetOrder = useCallback((newOrder: QuickPromptSetWithQpList[]) => {
    const orderedSetIds = newOrder.map(set => set.id);
    const newQpManagement = {
      ...qpManagement,
      orderedSetIds,
    };
    dispatch(updateQpManagement(newQpManagement));
  }, [dispatch, qpManagement]);


  let resultQpSets = [...quickPromptSets];

  // ソートと非表示化
  resultQpSets = resultQpSets
    .filter((set) => {
      return (
        (!set.isOfficial || set.quickPrompts.length > 0) &&
        (!set.language || set.language === lang) &&
        !qpManagement.hideSetIds.includes(set.id) &&
        !(set.type == QuickPromptSetType.UNCLASSIFIED && set.quickPrompts.length == 0)
      );
    })
    .map((set) => ({
      ...set,
      quickPrompts: set.quickPrompts
        .filter((qp) => !qpManagement.hideQpIds.includes(qp.id))
        .sort((a, b) => {
          // 下記のロジックでソート
          // - orderedQpIdsに含まれるものは優先的に上に表示し、orderedQpIdsの並び順にする
          // - orderedQpIdsに含まれないものは、baseOrderが設定されてるものを優先し、baseOrderの並び順にする
          // - orderedQpIdsに含まれておらず、baseOrderを設定されてないものは、createdAtの降順にする

          const indexA = qpManagement.orderedQpIds.indexOf(a.id);
          const indexB = qpManagement.orderedQpIds.indexOf(b.id);

          // orderedQpIds に含まれているかどうか
          const isAInOrdered = indexA !== -1;
          const isBInOrdered = indexB !== -1;


          // 1. orderedQpIds に含まれる両者同士なら、orderedQpIds の並び順
          if (isAInOrdered && isBInOrdered) {
            return indexA - indexB;
          }
          // 2. 一方だけが含まれていれば、それを上 (小さい) に
          if (isAInOrdered) {
            return -1;
          }
          if (isBInOrdered) {
            return 1;
          }

          // ここまで来た = 両方とも orderedQpIds に含まれていない

          // 3. baseOrder が両方ともあるなら、baseOrder 昇順
          const aBaseOrder = a.baseOrder ?? null;
          const bBaseOrder = b.baseOrder ?? null;

          if (aBaseOrder !== null && bBaseOrder !== null) {
            return aBaseOrder - bBaseOrder;
          }
          if (aBaseOrder !== null) {
            return -1;
          }
          if (bBaseOrder !== null) {
            return 1;
          }

          // 4. 最後の手段として createdAt の昇順（古い日付を先に）
          const dateComparison = new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
          return dateComparison;
        })
    }))
  ;

  resultQpSets = sortQpSetByQpManagementOrder(
    resultQpSets,
    qpManagement
  );


  // お気に入りの追加 (常に一番上)
  const qpList = getAllQuickPrompts(resultQpSets);
  const bookmarkedIds = bookmarkedQuickPromptIds.filter(id => qpList.some(qp => qp.id === id));
  if (bookmarkedIds.length > 0) {
    const favoriteQpSet: QuickPromptSetWithQpList = {
      id: FAVORITE_QP_SET_ID,
      officialCode: undefined,
      type: QuickPromptSetType.NORMAL,
      name: t("quickPrompt:qpList.favorite"),
      icon: "StarOutline",
      createdAt: "",
      updatedAt: "",
      isOfficial: false,
      isShared: false,
      authorityType: QpAuthorityType.USER,
      quickPrompts: qpList.filter((qp) => {
        return bookmarkedQuickPromptIds.includes(qp.id);
      }),
    };
    resultQpSets.unshift(favoriteQpSet);
  }

  return (
    <QpListPresenter
      quickPromptSets={resultQpSets}
      expandedSets={expandedSets}
      handleToggleSet={handleToggleSet}
      bookmarkedQuickPromptIds={bookmarkedQuickPromptIds}
      handleAddBookmark={handleAddBookmark}
      handleRemoveBookmark={handleRemoveBookmark}
      handleUpdateSetOrder={handleUpdateSetOrder}
    />
  );
};

export default QpListContainer;
