import yaml from 'js-yaml';
import React, { useState } from 'react';
import { Menu, MenuItem, ListItemText } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import { getCurrentMembership } from '../../auth/auth.type';
import { updateQpManagement, deleteQuickPrompt } from '../quickPrompt.slice';
import { useToast } from '../../generic/hooks/useToast';
import { useTranslation } from 'react-i18next';
import QpShareModal from '../../quickPromptManagement/containers/QpShareModal';
import { Link, useNavigate } from 'react-router-dom';
import { QuickPrompt, QuickPromptSet } from '../quickPrompt.type';
import { useConfirmModal } from '../../generic/hooks/useConfirmModal';
import { QpAuthorityType } from '../quickPrompt.constant';
import apiAxios from '@/lib/axios';
import { copyToClipboard } from '@/common/utils/copy';
import useLoading from '../../generic/hooks/useLoading';

interface QpListQpMenuContainerProps {
  anchorPosition: { mouseX: number; mouseY: number } | null;
  open: boolean;
  quickPromptSet?: QuickPromptSet;
  quickPrompt?: QuickPrompt;
  bookmarkedQuickPromptIds: string[];

  // 「非表示」ボタンを消す?
  hideHideButton?: boolean;

  handleMenuClose: (event: React.MouseEvent<HTMLElement>) => void;
  handleAddBookmark?: (quickPromptId: string) => void;
  handleRemoveBookmark?: (quickPromptId: string) => void;
}

const QpListQpMenuContainer: React.FC<QpListQpMenuContainerProps> = ({
  anchorPosition,
  open,
  quickPrompt,
  bookmarkedQuickPromptIds,
  handleMenuClose,
  handleAddBookmark,
  handleRemoveBookmark,
  hideHideButton = false,
}) => {
  const { setConfirmModal } = useConfirmModal();
  const { showToast } = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const qpManagement = useSelector((state: RootState) => state.quickPrompt.qpManagement);
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const currentMembership = getCurrentMembership(loginUser);
  const [sharedModalOpened, setSharedModalOpened] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const isOfficialQpEditor = currentMembership?.team.isOfficialQpEditor;

  const isBookmarked = quickPrompt && bookmarkedQuickPromptIds.includes(quickPrompt.id);

  const hasEditorAuth = quickPrompt?.authorityType !== QpAuthorityType.USER;
  const hasOwnerAuth = quickPrompt?.authorityType === QpAuthorityType.OWNER;

  const handleSharedModalClosed = () => {
    setSharedModalOpened(false);
  };

  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    handleMenuClose(event);
    setSharedModalOpened(true);
  };

  const handleHideClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleMenuClose(event);
    if (quickPrompt) {
      const newQpManagement = {
        ...qpManagement,
        hideQpIds: [...qpManagement.hideQpIds, quickPrompt.id],
      };
      dispatch(updateQpManagement(newQpManagement));
      // t:{{name}}を非表示にしました
      showToast(t(
        'quickPrompt:qpList.messages.hideSuccess',
        {name: quickPrompt.name}
      ), 'success')
    }
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleMenuClose(event);
    if (quickPrompt) {
      setConfirmModal(
        // t: クイックプロンプト削除確認
        t('quickPrompt:qpList.modal.deleteConfirmation.title'),
        // t: 「{{name}}」クイックプロンプトを削除します。よろしいですか？
        t('quickPrompt:qpList.modal.deleteConfirmation.message', { name: quickPrompt.name }),
        () => {
          dispatch(deleteQuickPrompt(quickPrompt.id));
          // t: {{name}}を削除しました。
          showToast(t('quickPrompt:qpList.messages.deleteSuccess', { name: quickPrompt.name }), 'success');
          // もし /quick-prompts/{id}/threads/newにいる場合はトップページに遷移
          if (window.location.pathname.includes(`/quick-prompts/${quickPrompt.id}/threads/new`)) {
            navigate('/');
          }
        }
      );
    }
  };

  const handleRawDataCopyClick = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleMenuClose(event);
    if (quickPrompt) {
      setIsLoading(true);
      const response = await apiAxios.get<any>(
        `/quick-prompts/${quickPrompt.id}/raw-data`
      );
      copyToClipboard(yaml.dump(response.data));

      setIsLoading(false);
      // t:「{{name}}」の生データをコピーしました
      showToast(t(
        'quickPrompt:qpList.messages.rawDataCopied',
        {name: quickPrompt.name}
      ), 'success')
    }
  };

  return (
    <>
      <Menu
        open={open}
        onClose={handleMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          anchorPosition ? { top: anchorPosition.mouseY, left: anchorPosition.mouseX } : undefined
        }
        keepMounted
        disableRestoreFocus
      >
        {hasEditorAuth && (
          <MenuItem
            key="edit"
            onClick={(e) => {
              e.stopPropagation();
              handleMenuClose(e);
            }}
            component={Link}
            to={`/quick-prompts/${quickPrompt?.id}/edit`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {
              // t:編集
              t('quickPrompt:qpList.actions.edit')
            }
          </MenuItem>
        )}
        {
          handleAddBookmark && handleRemoveBookmark &&
          <MenuItem
            key="bookmark"
            onClick={(e) => {
              e.stopPropagation();
              if (quickPrompt) {
                isBookmarked
                  ? handleRemoveBookmark(quickPrompt.id)
                  : handleAddBookmark(quickPrompt.id);
              }
              handleMenuClose(e);
            }}
          >
            <ListItemText
              primary={
                isBookmarked ?
                // t:お気に入り解除
                t('quickPrompt:qpList.actions.removeBookmark') :
                // t:お気に入り追加
                t('quickPrompt:qpList.actions.addBookmark')
              }
            />
          </MenuItem>
        }
        <MenuItem
          key="duplicate"
          onClick={(e) => {
            e.stopPropagation();
            handleMenuClose(e);
          }}
          component={Link}
          to={`/quick-prompts/new?copyFrom=${quickPrompt?.id}`}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {
            // t:複製
            t('quickPrompt:qpList.actions.duplicate')
          }
        </MenuItem>
        {hasEditorAuth && (
          <MenuItem key="share" onClick={handleShareClick}>{
            // t:共有
            t('quickPrompt:qpList.actions.share')
          }</MenuItem>
        )}
        {
          hasEditorAuth && isOfficialQpEditor &&
          <MenuItem key="copyRadData" onClick={handleRawDataCopyClick}>{
            // t:生データコピー
            t('quickPrompt:qpList.actions.copyRawData')
          }</MenuItem>
        }
        { !hideHideButton &&
          <MenuItem key="hide" onClick={handleHideClick}>{
            // t:非表示
            t('quickPrompt:qpList.actions.hide')
          }</MenuItem>
        }
        {hasOwnerAuth && (
          <MenuItem
            key="delete"
            onClick={handleDeleteClick}
            sx={{ color: 'error.main', fontWeight: 'bold' }}>{
              // t:削除
              t('quickPrompt:qpList.actions.delete')
          }</MenuItem>
        )}
      </Menu>

      {quickPrompt && currentMembership && (
        <QpShareModal
          open={sharedModalOpened}
          onClose={handleSharedModalClosed}
          sharedObject={quickPrompt}
          team={currentMembership.team}
          userAuthority={quickPrompt.authorityType}
        />
      )}
    </>
  );
}

export default QpListQpMenuContainer;
