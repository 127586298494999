import { QpChoiceOptions, QpManagement, QuickPromptSetWithQpList, QuickPromptWithVersion, ReqQpProperty } from "./quickPrompt.type";

export const getTextareaWithToolbarKey = (qp: QuickPromptWithVersion): string | undefined => {
  let textareaWithToolbarKey = '';
  const targetVersion = qp.targetVersion || qp.latestVersion;
  const uiSchema: Record<string, any> = targetVersion.uiSchema;
  for (const key in uiSchema) {
    const schema = uiSchema[key];
    if (schema['ui:widget'] === 'textareaWithToolbar') {
      textareaWithToolbarKey = key;
      break;
    }
  }
  return textareaWithToolbarKey || undefined;
};

export const getUiSchema = (qp: QuickPromptWithVersion,  initialFile: any): Record<string, any> => {
  const key = getTextareaWithToolbarKey(qp);
  const targetVersion = qp.targetVersion || qp.latestVersion;
  const uiSchema: Record<string, any> = targetVersion.uiSchema;

  if (!key) {
    return uiSchema;
  }

  const updatedUiSchema: Record<string, any> = { ...uiSchema };
  const schema = updatedUiSchema[key];
  if (schema) {
    updatedUiSchema[key as keyof typeof updatedUiSchema] = {
      ...schema,
      'ui:options': {
        ...(schema['ui:options'] as any),
        initialFile: initialFile,
      },
    };
  }
  return updatedUiSchema;
};

export const getFormSchema = (qp: QuickPromptWithVersion): Record<string, any> => {
  const key = getTextareaWithToolbarKey(qp);
  const targetVersion = qp.targetVersion || qp.latestVersion;
  const formSchema: Record<string, any> = targetVersion.formSchema;

  if (!key) {
    return formSchema;
  }

  const updatedFormSchema: Record<string, any> = JSON.parse(JSON.stringify(formSchema));

  return updatedFormSchema;
}

export const getFormSchemaTitle = (qp: QuickPromptWithVersion, key: string): string => {
  const targetVersion = qp.targetVersion || qp.latestVersion;
  const formSchema: Record<string, any> = targetVersion.formSchema;
  const properties = formSchema['properties'];

  return properties[key]?.title;
}


export const getQpOptions = (property: ReqQpProperty): QpChoiceOptions[] => {
  if (property.type === 'selectBox') {
    return property.detail.selectBox?.options || [];
  }
  if (property.type === 'checkBox') {
    return property.detail.checkBox?.options || [];
  }
  if (property.type === 'radioButton') {
    return property.detail.radioButton?.options || [];
  }
  return [];
}



export const sortQpSetByQpManagementOrder = (
  qpSets: QuickPromptSetWithQpList[],
  qpManagement: QpManagement
): QuickPromptSetWithQpList[] => {
  return qpSets.sort((a, b) => {
    // orderedSetIds 上での位置を判定
    const indexA = qpManagement.orderedSetIds.indexOf(a.id);
    const indexB = qpManagement.orderedSetIds.indexOf(b.id);

    // orderedSetIds に含まれているかどうかをブールで扱うと分かりやすい
    const isAInOrdered = indexA !== -1;
    const isBInOrdered = indexB !== -1;

    // 1. 両者とも orderedSetIds に含まれている場合は、index の昇順
    if (isAInOrdered && isBInOrdered) {
      return indexA - indexB;
    }
    // 2. 一方だけ含まれていれば、含まれている方を優先(先頭)
    if (isAInOrdered) {
      return -1;
    }
    if (isBInOrdered) {
      return 1;
    }

    // ここまで来たら「両方とも orderedSetIds に含まれていない」

    // 3. baseOrder が両方ある場合 → baseOrder の昇順
    //    どちらかだけある場合はそちらを先に
    const aBaseOrder = a.baseOrder ?? null;
    const bBaseOrder = b.baseOrder ?? null;

    if (aBaseOrder !== null && bBaseOrder !== null) {
      return aBaseOrder - bBaseOrder;
    }
    if (aBaseOrder !== null) {
      return -1;
    }
    if (bBaseOrder !== null) {
      return 1;
    }

    // 4. 最後に createdAt の昇順 (古いほうを先に)
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });
};
