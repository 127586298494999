import { QpSingleCheckBoxDetail } from "@/components/features/quickPrompt/quickPrompt.type";
import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { ReqQpPropertyEdit } from "../../qpEditForm.type";
import QpRequiredCheckbox from "./QpRequiredCheckbox";
// t:i18n
import { useTranslation } from "react-i18next";

interface Props {
  property: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  detail: QpSingleCheckBoxDetail;
  onChange: (data: QpSingleCheckBoxDetail) => void;
}

function QpSingleCheckboxSettingFields({
  detail,
  property,
  allProperties,
  onChange,
}: Props) {
  const { t } = useTranslation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    onChange({
      ...detail,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <>
      <QpRequiredCheckbox
        property={property}
        allProperties={allProperties}
        checked={detail.isRequired || false}
        onChange={handleInputChange}
      />
      {property.isAdvancedMode && (
        <>
          <Box />
          <FormControlLabel
            control={
              <Checkbox
                checked={detail.defaultValue || false}
                onChange={handleInputChange}
                name="defaultValue"
              />
            }
            // t:初期状態でチェックを入れる
            label={t("quickPromptEdit:settingFields.defaultChecked")}
          />
          <TextField
            // t:説明文
            label={t("quickPromptEdit:settingFields.description")}
            name="helpText"
            value={detail.helpText || ""}
            onChange={handleInputChange}
            fullWidth
            multiline
            minRows={1}
            maxRows={9}
            margin="normal"
          />
          <TextField
            // t:チェック時のプロンプト
            label={t("quickPromptEdit:settingFields.checkedPrompt")}
            name="prompt"
            value={detail.prompt || ""}
            onChange={handleInputChange}
            multiline
            minRows={2}
            maxRows={9}
            fullWidth
            // t:未入力時は項目名がそのままプロンプトとして使われます。
            placeholder={t("quickPromptEdit:settingFields.optionalPromptPlaceholder")}
            margin="normal"
          />
        </>
      )}
    </>
  );
}

export default QpSingleCheckboxSettingFields;
