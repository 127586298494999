// QpCategoryCreateModal.tsx (例)
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField
} from '@mui/material';
import { createQuickPromptSet } from '../../quickPrompt/quickPrompt.slice';
import { useAppDispatch } from '@/store';
import { useTranslation } from 'react-i18next';

type CategoryCreateDialogProps = {
  open: boolean;
  onClose: () => void;
};

const QpCategoryCreateModal: React.FC<CategoryCreateDialogProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const [categoryName, setCategoryName] = useState('');
  const { t } = useTranslation();

  const handleSave = async () => {
    // 入力したカテゴリ名を用いてAPI呼び出し (redux-thunk)
    if (categoryName.trim()) {
      await dispatch(createQuickPromptSet(categoryName.trim()));
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {/* t:カテゴリ作成 */}
        {t('quickPromptManagement:categoryCreateModal.title')}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          // t:カテゴリ名
          label={t('quickPromptManagement:categoryCreateModal.fields.name')}
          fullWidth
          variant="outlined"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {/* t:キャンセル */}
          {t('common:button.cancel')}
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
        >
          {/* t:保存 */}
          {t('common:button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QpCategoryCreateModal;
