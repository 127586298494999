import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentMembership, hasSuperAdminAuth } from '../../auth/auth.type';
import SharedCSVDownloadPresenter from '../presenters/SharedCSVDownloadPresenter';
import axios from 'axios';
import { downloadCsv } from '@/lib/csv';
import useLoading from '../../generic/hooks/useLoading';
import { useTranslation } from 'react-i18next';


const MessageCSVDownloadContainer: React.FC = () => {
  const { t } = useTranslation();

  const { setIsLoading } = useLoading();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const membership = getCurrentMembership(loginUser);

  if (!(
    loginUser &&
    membership &&
    hasSuperAdminAuth(loginUser) &&
    membership.team.quota.vaultEnabled
  )) {
    return null;
  }

  const handleDownload = async (termFrom: string, termTo: string) => {
    setIsLoading(true);
    try {
      await downloadCsv('/vault/messages/csv/download', { termFrom, termTo });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        if (error.response.data.errorDetail === 'your_team_is_not_vault_enabled') {
          // t:現在のプランではこの機能を使用できません。
          window.alert(t('vault:csvDownload.error.planNotSupported'));
        } else {
          // t:ダウンロードに失敗しました。
          window.alert(t('vault:csvDownload.error.downloadFailed'));
        }
      } else {
        // t:エラーが発生しました。
        window.alert(t('vault:csvDownload.error.general'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return <SharedCSVDownloadPresenter
    // t:VAULT メッセージ履歴
    title={t("vault:csvDownload.message.title")}
    // t:投稿されたメッセージや生成された文章の履歴をCSVでダウンロードすることができます。
    description={t('vault:csvDownload.message.description')}
    // t:各メンバーが具体的にどんなメッセージを投稿してるか、AIがどんな文章を生成しているか知りたい場合にご利用ください。
    description2={t('vault:csvDownload.message.usage')}
    onDownload={handleDownload}
  />;
};

export default MessageCSVDownloadContainer;
