import theme from "@/theme";
import styled from "@emotion/styled";
import { Box, SxProps, Typography } from "@mui/material";


export const ContentsWrapper = styled.div`
  display: flex;
  height: 100vh;
  padding-top: 64px;
`;

export const ContentsWrapperWithLeftSidebar = styled(ContentsWrapper)`
  ${() => theme.breakpoints.up('md')} {
    padding-left: 275px;
  }
`;

export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 24px 24px 0 0;
  margin-right: 5px;
`;

export const MainAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  background-color: #fff;
  overflow: auto; /* コンテンツが縦幅を超えた場合にスクロール可能にする */
  ${() => theme.breakpoints.up('md')} {
    border-radius: 24px 0 0 0;
  }
`;


export const FormArea = styled.div`
  flex: 1;
  margin-right: 20px;
  margin-bottom: 70px;

  ${theme.breakpoints.up('md')} {
    max-width: min(1000px, 100%);
  }

  .MuiGrid-item:has(.qp-form-lg-4) {
    ${() => theme.breakpoints.up('md')} {
      flex-basis: 33%;
    }
  }

  #root__description {
    white-space: pre-wrap;
  }
`;


export const LeftSideBarWrapper = styled(Box)<{ open?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 275px;
  min-width: 275px;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

export const RightSideBarWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 240px;
  min-width: 240px;
  padding: 24px;
  background-color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
`;

export const LeftSidebarSubTitle: React.FC<React.PropsWithChildren & { sx?: SxProps }> = ({ children, sx }) => {
  return (
    <Typography variant="subtitle2" sx={{ pl: 2, pt: 2, pb: 0.2, ...sx }}>
      {children}
    </Typography>
  )
};

export const CenteredMainAreaWrapper = styled(MainAreaWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

