import { QpCheckBoxDetail } from "@/components/features/quickPrompt/quickPrompt.type";
import QpChoicePropertySettingFields from "./QpChoicePropertySettingFields";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { ReqQpPropertyEdit } from "../../qpEditForm.type";
import { useTranslation } from "react-i18next";

interface Props {
  property: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  detail: QpCheckBoxDetail;
  onChange: (data: QpCheckBoxDetail) => void;
}

function QpCheckBoxSettingFields({
  property,
  allProperties,
  detail,
  onChange,
}: Props) {
  const { t } = useTranslation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    onChange({
      ...detail,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <>
      <QpChoicePropertySettingFields
        property={property}
        allProperties={allProperties}
        detail={detail}
        singleDefault={false}
        onChange={(baseData) => onChange(
          {
          ...detail,
          ...baseData,
          }
        )}
        otherField={
          property.isAdvancedMode ?
            <>
              <Box />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={detail.isInline || false}
                    onChange={handleInputChange}
                    name="isInline"
                  />
                }
                // t:横並び
                label={t("quickPromptEdit:settingFields.inlineLabel")}
              />
            </> :
            <></>
        }
      />
    </>
  );
}

export default QpCheckBoxSettingFields;
