import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  TableContainer,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '@/store';
import { useToast } from '../../generic/hooks/useToast';
import { useTranslation } from 'react-i18next';
import { updateQpManagement } from '../../quickPrompt/quickPrompt.slice';
import { convertStrToLanguage } from '../../setting/setting.utils';
import { QuickPromptSetType } from '../../quickPrompt/quickPrompt.constant';

interface QpVisibilityManagementModalProps {
  open: boolean;
  onClose: () => void;
}

const QpVisibilityManagementModal: React.FC<QpVisibilityManagementModalProps> = ({
  open,
  onClose,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { showToast } = useToast();
  const quickPromptSets = useSelector((state: RootState) => state.quickPrompt.quickPromptSets);
  const qpManagement = useSelector((state: RootState) => state.quickPrompt.qpManagement);

  // ローカル状態で表示/非表示を管理
  const [setVisibilityState, setSetVisibilityState] = useState<{ [key: string]: boolean }>({});
  const [qpVisibilityState, setQpVisibilityState] = useState<{ [key: string]: boolean }>({});

  // 言語
  const lang = convertStrToLanguage(i18n.language);

  useEffect(() => {
    // qpManagementに基づいてvisibilityStateを初期化
    const initialSetVisibilityState: { [key: string]: boolean } = {};
    const initialQpVisibilityState: { [key: string]: boolean } = {};

    quickPromptSets.forEach((set) => {
      const isSetHidden = qpManagement.hideSetIds.includes(set.id);
      initialSetVisibilityState[set.id] = !isSetHidden;

      set.quickPrompts.forEach((qp) => {
        const isQpHidden = qpManagement.hideQpIds.includes(qp.id);
        initialQpVisibilityState[qp.id] = !isQpHidden;
      });
    });

    setSetVisibilityState(initialSetVisibilityState);
    setQpVisibilityState(initialQpVisibilityState);
  }, [quickPromptSets, qpManagement]);

  const handleSetCheckboxChange = (id: string) => {
    setSetVisibilityState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleQpCheckboxChange = (id: string) => {
    setQpVisibilityState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleSave = async () => {
    // visibilityStateに基づいて新しいqpManagementを作成
    const newHideSetIds: string[] = [];
    const newHideQpIds: string[] = [];

    quickPromptSets.forEach((set) => {
      if (!setVisibilityState[set.id]) {
        newHideSetIds.push(set.id);
      }
      set.quickPrompts.forEach((qp) => {
        if (!qpVisibilityState[qp.id]) {
          newHideQpIds.push(qp.id);
        }
      });
    });

    const newQpManagement = {
      ...qpManagement,
      hideSetIds: newHideSetIds,
      hideQpIds: newHideQpIds,
    };

    await dispatch(updateQpManagement(newQpManagement));
    showToast(
      t('quickPromptManagement:visibilityManagement.messages.saveSuccess'),
      'success'
    );
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const targetQpSets = quickPromptSets
    .filter((set) => {
      return (
      // 言語フィルタ（多言語対応を考慮）
        (!set.language || set.language === lang) &&
        (set.type == QuickPromptSetType.NORMAL)
      );
    })
    .map((set) => {
      // QPをユーザが指定した並び順でソート
      const sortedQuickPrompts = [...set.quickPrompts].sort(
        (a, b) =>
          qpManagement.orderedQpIds.indexOf(a.id) -
          qpManagement.orderedQpIds.indexOf(b.id)
      );
      return {
        ...set,
        quickPrompts: sortedQuickPrompts,
      };
    })
    .sort(
      (a, b) =>
        qpManagement.orderedSetIds.indexOf(a.id) -
        qpManagement.orderedSetIds.indexOf(b.id)
    );

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          maxHeight: '80%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          // ↓ Box では overflow を付けず、TableContainer に任せるのがポイント
          // overflowY: 'auto',
        }}
      >
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          {t('quickPromptManagement:visibilityManagement.title')}
        </Typography>

        {/* テーブルヘッダー固定のために TableContainer + stickyHeader を使用 */}
        <TableContainer sx={{ maxHeight: '60vh' /* お好みで */ }}>
          <Table
            size="small"
            stickyHeader
            sx={{
              '& .MuiTableCell-stickyHeader': {
                backgroundColor: '#fff',
                top: '-1px',
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>{t('quickPromptManagement:visibilityManagement.columns.display')}</TableCell>
                <TableCell>{t('quickPromptManagement:visibilityManagement.columns.category')}</TableCell>
                <TableCell>{t('quickPromptManagement:visibilityManagement.columns.quickPrompt')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {targetQpSets.map((set) => (
                <React.Fragment key={set.id}>
                  {/* セット行 */}
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={setVisibilityState[set.id] || false}
                        onChange={() => handleSetCheckboxChange(set.id)}
                      />
                    </TableCell>
                    <TableCell>{set.name}</TableCell>
                    <TableCell />
                  </TableRow>
                  {/* セットに含まれるクイックプロンプト行 */}
                  {set.quickPrompts.map((qp) => (
                    <TableRow key={qp.id}>
                      <TableCell>
                        <Checkbox
                          checked={qpVisibilityState[qp.id] || false}
                          onChange={() => handleQpCheckboxChange(qp.id)}
                          disabled={!setVisibilityState[set.id]}
                        />
                      </TableCell>
                      <TableCell />
                      <TableCell sx={{ color: !setVisibilityState[set.id] ? 'text.disabled' : 'inherit' }}>
                        {qp.name}
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCancel} sx={{ mr: 1 }}>
            {t('common:button.cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            {t('common:button.save')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default QpVisibilityManagementModal;
