import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Divider,
  useTheme,
} from '@mui/material';
import { QpEditStep1Data, QpEditStep2Data, ReqQpPropertyEdit } from '../qpEditForm.type';
import { Add } from '@mui/icons-material';
import QpEditStep2PreviewContainer from './QpEditStep2PreviewContainer';
import QpEditStep2ItemListContainer from './QpEditStep2ItemListContainer';
import QpEditStep2AddItemModal from './QpEditStep2AddItemModal';
import { useTranslation } from 'react-i18next';

interface Step2FormProps {
  data: QpEditStep2Data;
  step1Data: QpEditStep1Data;
  onChangeData: (data: QpEditStep2Data) => void;
  onChangeComplete: (completed: boolean) => void;
  isScreenDownMd: boolean;
  isScreenDownSm: boolean;
}

function QpEditStep2FormBuildContainer({
  data,
  step1Data,
  onChangeData,
  onChangeComplete,
  isScreenDownMd,
  isScreenDownSm,
}: Step2FormProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [properties, setProperties] = useState<ReqQpPropertyEdit[]>(data.properties || []);
  const [openAddItemModal, setOpenAddItemModal] = useState(false);

  useEffect(() => {
    onChangeData({ properties });
    onChangeComplete(properties.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties]);

  return (
   <Box
      display="flex"
      flexDirection={isScreenDownMd ? 'column' : 'row'}
      p={2}
      sx={{
        flexGrow: 1,
        overflow: 'auto',
        height: '100%'
      }}
    >
      {/* 左側: 項目一覧 */}
      <Box flex={1} pr={isScreenDownMd ? 0 : 1}>
        <Typography variant="h6" gutterBottom>
          {
            isScreenDownSm
              // t: 2. フォーム作成
              ? `2. ${t("quickPromptEdit:step2.label")}`
              : t("quickPromptEdit:step2.itemListTitle")
          }
        </Typography>
        <Divider />
        <QpEditStep2ItemListContainer
          properties={properties}
          setProperties={setProperties}
        />
        {properties.length === 0 && (
          <Typography variant="body2" color="textSecondary" mt={2}>
            {/*
              t:「項目追加ボタン」を押して、項目を追加しましょう。
            */}
            {t("quickPromptEdit:step2.noItemsYet")}
          </Typography>
        )}
        <Box mt={4} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setOpenAddItemModal(true)}
          >
            {/*
              t:項目追加
            */}
            {t("quickPromptEdit:step2.addItem")}
          </Button>
        </Box>
      </Box>

      {/* 右側: プレビュー */}
      {!isScreenDownMd && (
        <Box display="flex" flex={1} ml={3} borderLeft={`1px solid ${theme.palette.divider}`}>
          <Box p={2} ml={2} flex={1} style={{ backgroundColor: theme.palette.background.default }}>
            <Typography variant="h6" gutterBottom>
              {/*
                t:プレビュー
              */}
              {t("quickPromptEdit:step2.preview")}
            </Typography>
            <QpEditStep2PreviewContainer
              step1Data={step1Data}
              properties={properties}
            />
          </Box>
        </Box>
      )}

      {/* 項目追加モーダル */}
      <QpEditStep2AddItemModal
        open={openAddItemModal}
        onClose={() => setOpenAddItemModal(false)}
        onAddItem={(item) => {
          setProperties((prev) => [...prev, item]);
          setOpenAddItemModal(false);
        }}
        existingProperties={properties}
      />
    </Box>
  );
}

export default QpEditStep2FormBuildContainer;
