import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentMembership, hasAdminAuth } from '../../auth/auth.type';
import LatestUsageStatisticsPresenter from '../presenters/LatestUsageStatisticsPresenter';
const LatestUsageStatisticsContainer: React.FC = () => {
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const membership = getCurrentMembership(loginUser)
  if (!loginUser || !membership) {
    return;
  }
  const team = membership.team;
  if (!hasAdminAuth(loginUser)) {
    return;
  }

  return <LatestUsageStatisticsPresenter
    team={team}
  />
};

export default LatestUsageStatisticsContainer;