// QpEditStep3TemplateHelper.tsx (統合後)

import React from 'react';
import { ReqQpPropertyEdit } from '../qpEditForm.type';
import { QpPropertyType } from '../../quickPrompt/quickPrompt.type';

import {
  Box,
  Typography,
  Divider,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface QpEditStep3TemplateHelperProps {
  properties: ReqQpPropertyEdit[];
  editor?: any; // TiptapのEditorインスタンス
}

function QpEditStep3TemplateHelper({ properties, editor }: QpEditStep3TemplateHelperProps) {
  const { t } = useTranslation();

  if (!editor) {
    return null;
  }

  const insertVariable = (key: string, label: string) => {
    editor
      .chain()
      .focus()
      .insertContent({
        type: 'variable',
        attrs: { name: key, 'data-label': label },
      })
      .run();
  };

  return (
    <Box
      sx={{
        width: 275,
        padding: 2,
        backgroundColor: 'background.default',
        height: '100%',
        overflowY: 'auto' // 多い場合はスクロール
      }}
    >
      {/* t:テンプレートヘルパー */}
      <Typography variant="body1" gutterBottom>
        {t("quickPromptEdit:step3.templateHelper")}
      </Typography>
      {/* t:利用者が入力した値や選択肢に設定されたプロンプトを挿入できます。 */}
      <Typography variant="body2" color="textSecondary" mb={2}>
        {t("quickPromptEdit:step3.templateHelperDescription")}
      </Typography>
      <Divider sx={{ mb: 1 }} />
      <List dense>
        {properties.map((property) => {
          const label = property.label;
          const key = property.key;
          const type = property.type;

          // AI選択と詳細設定切り替えはスキップ
          if (
            type === QpPropertyType.TOGGLE_ADVANCED_SETTING ||
            type === QpPropertyType.SELECT_AI_MODELS
          ) {
            return null;
          }

          return (
            <React.Fragment key={key}>
              <ListItem dense disableGutters key={key}>
                <ListItemText primary={label} />
                <Button
                  size="small"
                  variant="outlined"
                  // t:挿入
                  onClick={() => insertVariable(key, label)}
                >
                  {t("quickPromptEdit:step3.insert")}
                </Button>
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );
}

export default QpEditStep3TemplateHelper;
