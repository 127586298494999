import React from 'react';
import { Box } from '@mui/material';
import { RightSideBarWrapper } from '@/components/ui/StyledComponents';

const QpEditRightSidebar: React.FC = () => {
  return;
  return <RightSideBarWrapper
      sx={{
        paddingLeft: "10px !important",
        paddingRight: "10px !important",
        overflowY: "auto",
        maxHeight: "100vh"
      }}
    >
    <Box mb={4}>
      TODO: マニュアルなど
    </Box>
  </RightSideBarWrapper>;
};

export default QpEditRightSidebar;
