import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tab, Tabs, CircularProgress, Backdrop, Typography, Paper, useTheme } from '@mui/material';
import { AppDispatch, RootState } from '@/store';
import ReportConditionForm from './ReportConditionForm';
import ReportSummary from './ReportSummary';
import ReportTimelineChart from './ReportTimelineChart';
import ReportDetailTable from './ReportDetailTable';
import { UnitType } from '../report.type';
import { GraphType, TabType } from '../report.constant';
import { fetchReport } from '../report.slice';
import { getCurrentMembership, hasAdminAuth } from '../../auth/auth.type';
import { useParams } from 'react-router-dom';
import ReportModelStatistics from './ReportModelStatistics';
import { useTranslation } from 'react-i18next';

const ReportPage: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { report, loading, error } = useSelector((state: RootState) => state.report);
  const { teamId: supervisorQueryTeamId } = useParams<{ teamId: string }>();

  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const membership = getCurrentMembership(loginUser);

  const [unitType, setUnitType] = useState(UnitType.DAY);
  const [termFrom, setTermFrom] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  const [termTo, setTermTo] = useState(new Date(new Date().setDate(new Date().getDate() - 1)));
  const [selectedGraph, setSelectedGraph] = useState<GraphType>('useCount');
  const [selectedTab, setSelectedTab] = useState<TabType>('usage');

  useEffect(() => {
    dispatch(fetchReport({ teamId, unitType, termFrom, termTo }));
  }, [dispatch, unitType, termFrom, termTo]);

  // データを取得するチームIDを取得
  // システム運用画面かユーザー向け管理画面かで取り方が変わる
  let teamId: string = ''
  if (supervisorQueryTeamId) {
    teamId = supervisorQueryTeamId;
  } else if (membership) {
    teamId = membership.team.id;
    // ユーザー画面の場合は管理者のみ
    if (!hasAdminAuth(loginUser)) {
      return;
    }
  }
  if (teamId === '') {
    return null;
  }

  const handleSubmit = (data: { unitType: UnitType, termFrom: Date, termTo: Date }) => {
    setUnitType(data.unitType);
    setTermFrom(data.termFrom);
    setTermTo(data.termTo);
  };

  const handleGraphChange = (_: React.SyntheticEvent, newValue: GraphType) => {
    setSelectedGraph(newValue);
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: TabType) => {
    setSelectedTab(newValue);
  };

  return (
    <Box position="relative">
      <Typography variant="h6" sx={{ mb: 2, color: 'primary.main' }}>
        {/* t:表示条件 */}
        {t("reports:condition.title")}
      </Typography>
      <ReportConditionForm onSubmit={handleSubmit} />
      {report && <>
        { report.teamUsageReports.length > 0 ? <>
          <Typography variant="h6" sx={{ mt:5, mb: 2, color: 'primary.main' }}>
            {/* t:集計サマリー */}
            {t("reports:summary.title")}
          </Typography>
          <ReportSummary report={report} />


          <Typography variant="h6" sx={{ mt:5, mb: 2, color: 'primary.main' }}>
            {/* t:時系列グラフ */}
            {t("reports:chart.title")}
          </Typography>
          <Box>
            <Box sx={{ overflowX: 'auto' }}>
              <Tabs
                value={selectedGraph}
                onChange={handleGraphChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                sx={{ mb: 2 }}
              >
                {/* t:利用回数 */}
                <Tab label={t("reports:chart.types.useCount")} value="useCount" />
                {/* t:利用メンバー数 */}
                <Tab label={t("reports:chart.types.memberCount")} value="memberNum" />
                {/* t:クレジット */}
                <Tab label={t("reports:chart.types.credits")} value="credit" />
                {/* t:文字数 */}
                <Tab label={t("reports:chart.types.characters")} value="chars" />
                {/* t:添付ファイル数 */}
                <Tab label={t("reports:chart.types.attachments")} value="fileNum" />
              </Tabs>
            </Box>
            <Paper elevation={1} sx={{ padding: theme.spacing(2), paddingRight: theme.spacing(5) }}>
              <ReportTimelineChart report={report} selectedGraph={selectedGraph} unitType={unitType}/>
            </Paper>
          </Box>

          <Typography variant="h6" sx={{ mt:5, mb: 2, color: 'primary.main' }}>
            {/* t:各モデルごとの統計 */}
            {t("reports:modelStatistics.title")}
          </Typography>
          <Box>
            <ReportModelStatistics report={report} />
          </Box>


          <Typography variant="h6" sx={{ mt:5, mb: 2, color: 'primary.main' }}>
            {/* t:データ表 */}
            {t("reports:dataTable.title")}
          </Typography>
          <Box>
            <Tabs value={selectedTab} onChange={handleTabChange} sx={{mb: 2}}>
              {/* t:利用量（全体） */}
              <Tab label={t("reports:dataTable.tabs.overallUsage")} value="usage" />
              {/* t:利用量（詳細） */}
              <Tab label={t("reports:dataTable.tabs.detailedUsage")} value="usageDetail" />
              {/* t:メンバー数 */}
              <Tab label={t("reports:dataTable.tabs.memberCount")} value="memberNum" />
            </Tabs>
            <ReportDetailTable report={report} selectedTab={selectedTab} unitType={unitType}/>
          </Box>
        </>: <>
          <Typography variant="body1" sx={{ mt: 3, mb: 2, color: 'text.secondary' }}>
            {/* t:ご指定の期間にはデータが存在しませんでした。 */}
            {t("reports:noData.message")}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
            {/* t:以下の注意事項をご確認の上、条件を再設定してください。 */}
            {t("reports:noData.instruction")}
          </Typography>
          <Box>
            {unitType === UnitType.DAY && (
              <Typography variant="body2" sx={{ mb: 1, color: 'text.secondary' }}>
                {/* t:・本日分のデータは、本日深夜に集計されます。 */}
                {t("reports:noData.notes.daily")}
              </Typography>
            )}
            {unitType === UnitType.WEEK && (
              <Typography variant="body2" sx={{ mb: 1, color: 'text.secondary' }}>
                {/* t:・今週分のデータは、日曜日の深夜に集計されます。 */}
                {t("reports:noData.notes.weekly")}
              </Typography>
            )}
            {unitType === UnitType.MONTH && (
              <Typography variant="body2" sx={{ mb: 1, color: 'text.secondary' }}>
                {/* t:・今月分のデータは、月末日の深夜に集計されます。 */}
                {t("reports:noData.notes.monthly")}
              </Typography>
            )}
            <Typography variant="body2" sx={{ mb: 1, color: 'text.secondary' }}>
              {/* t:・チームを作成する以前のデータは集計されません。 */}
              {t("reports:noData.notes.teamCreation")}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {/* t:・本機能の集計は2024年8月1日から開始されました。それ以前のデータは閲覧できません。 */}
              {t("reports:noData.notes.dataAvailability")}
            </Typography>
          </Box>
        </>}
      </>}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && <div>Error: {error}</div>}
    </Box>
  );
};

export default ReportPage;
