// シンプルな完全一致マップ（%sなど可変要素を含まないパターン）
export const errorTranslationMap: Record<string, string> = {
  // t:テンプレートが予期しない箇所で終了しています。
  "Unexpected end of template": "quickPromptEdit:qpEditForm.errorMap.unexpectedEndOfTemplate",
  // t:テンプレートが予期しない箇所で終了しています。
  "unexpected end of template": "quickPromptEdit:qpEditForm.errorMap.unexpectedEndOfTemplate",
  // t:テンプレートが予期しない箇所で終了しています。
  "unexpected 'end of template'": "quickPromptEdit:qpEditForm.errorMap.unexpectedEndOfTemplate",

  // t:式がない、または不正な式です。
  "tag name expected": "quickPromptEdit:qpEditForm.errorMap.tagNameExpected",

  // t:'{{' に対応する '}}' が存在しません。
  "expected 'end of print statement'": "quickPromptEdit:qpEditForm.errorMap.expectedEndOfPrintStatement",
  // t:'{{' に対応する '}}' が存在しません。
  "expected token 'end of print statement'": "quickPromptEdit:qpEditForm.errorMap.expectedTokenEndOfPrintStatement",

  // t:'{%' に対応する '%}' が存在しません。
  "expected 'end of statement block'": "quickPromptEdit:qpEditForm.errorMap.expectedEndOfStatementBlock",
  // t:'{%' に対応する '%}' が存在しません。
  "expected token 'end of statement block'": "quickPromptEdit:qpEditForm.errorMap.expectedTokenEndOfStatementBlock",

  // t:\n
  ",": "\n",
  // t:\n
  ".": "\n",
};

// 正規表現を用いたパターンマッチ用のテーブル
// key: 正規表現パターン（英語）
// value: 対応する日本語メッセージ（$1, $2などでキャプチャした文字を利用可）
export const errorRegexPatterns: Array<{pattern: RegExp; replace: string}> = [
  {
    pattern: /got '(.+?)'/,
    // t:(現状は '$1' になっています)
    replace: "quickPromptEdit:qpEditForm.errorRegex.got"
  },
  {
    pattern: /Encountered unknown tag '(.+?)'/,
    // t:不明な式 '$1' が使用されています。
    replace: "quickPromptEdit:qpEditForm.errorRegex.encounteredUnknownTag"
  },
  {
    pattern: /Jinja was looking for the following tags: '(.+?)'\. The innermost block that needs to be closed is '(.+?)'\./,
    // t:'$2' ブロックを閉じるために、'$1' のいずれかの式が必要です。
    replace: "quickPromptEdit:qpEditForm.errorRegex.jinjaLookingForTags"
  },
  {
    pattern: /expected token '(.+?)'/,
    // t:'$1' が必要です。
    replace: "quickPromptEdit:qpEditForm.errorRegex.expectedToken"
  },
  {
    pattern: /unexpected '(.+?)'/,
    // t:予期しない '$1' が見つかりました。
    replace: "quickPromptEdit:qpEditForm.errorRegex.unexpectedToken"
  },
  {
    pattern: /unexpected token '(.+?)'/,
    // t:予期しない '$1' が見つかりました。
    replace: "quickPromptEdit:qpEditForm.errorRegex.unexpectedToken"
  },
];
