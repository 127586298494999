import i18next from 'i18next';
import { QpPropertyType } from "../quickPrompt/quickPrompt.type";
import { errorRegexPatterns, errorTranslationMap } from "./qpEditForm.contant";
import { ReqQpPropertyEdit } from "./qpEditForm.type";

export function generatePropertyKey(
    propertyLabel: string
): string {
    // Unicode の記号クラス \p{P} を使用して、
    // 多言語の記号（「、」「。」「？」など）と空白を '_' に置換
    return propertyLabel.replace(/[\s\p{P}]+/gu, '_');
}

/**
 * 指定されたプロパティが「詳細設定」のプロパティかどうかを判定する
 *
 * 具体的には詳細設定のon/offの項目より下かどうかで判定する
 */
export function isAdvanceSettingProperty(
  property: ReqQpPropertyEdit,
  allProperties: ReqQpPropertyEdit[],
): boolean {
  const toggleAdvanceSettingIndex = allProperties.findIndex(
    p => p.type ===  QpPropertyType.TOGGLE_ADVANCED_SETTING
  );
  const currentPropertyIndex = allProperties.findIndex(p => p.id === property.id);
  return (
    toggleAdvanceSettingIndex >= 0 &&
    toggleAdvanceSettingIndex < currentPropertyIndex
  );
}

/**
 * jinja2テンプレートのエラーメッセージを翻訳する関数
 *
 * 1. 完全一致チェック
 * 2. 部分一致チェック
 * 3. 正規表現パターンでマッチ
 *
 * の順番で調べ、それぞれに該当する翻訳キーを i18next で実際のテキストへ置き換えます。
 *
 * ※ 該当する翻訳が無い場合は英語のまま返す。
 */
export function translateJinjaErrorMessage(englishMessage: string): string {
  console.debug("translateJinjaErrorMessage", englishMessage);

  // 1. 完全一致チェック
  if (errorTranslationMap[englishMessage]) {
    // errorTranslationMap[...] は "quickPromptEdit:qpEditForm.errorMap.XXX" のような翻訳キーなので、
    // i18next.t() で実際の翻訳文字列に変換
    return i18next.t(errorTranslationMap[englishMessage]);
  }

  // 2. 部分一致チェック - 全ての一致を変換
  let translatedMessage = englishMessage;
  for (const key in errorTranslationMap) {
    if (translatedMessage.includes(key)) {
      // errorTranslationMap[key] も同様に翻訳キーを返す
      translatedMessage = translatedMessage.replace(
        key,
        i18next.t(errorTranslationMap[key])
      );
    }
  }

  // 3. 正規表現パターンマッチ
  for (const { pattern, replace } of errorRegexPatterns) {
    const match = translatedMessage.match(pattern);
    if (match) {
      // `replace` は "quickPromptEdit:qpEditForm.errorRegex.unexpectedToken" のような翻訳キー
      // まず i18next.t() でテンプレート文字列を取得（例: "予期しない '$1' が見つかりました。"）
      const tKey = i18next.t(replace);

      // 取得したテンプレート文字列中の $1, $2, ... を、マッチした文字列で置き換える
      // "$1" => match[1], "$2" => match[2], ...
      translatedMessage = translatedMessage.replace(pattern, () => {
        return tKey.replace(/\$(\d+)/g, (_, index) => match[Number(index)]);
      });
    }
  }

  // 該当する翻訳がなければ英語のまま返す
  return translatedMessage;
}
