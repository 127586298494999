import { Box, Button, Typography, useTheme } from '@mui/material';
import { QpEditStep4Data } from '../qpEditForm.type';
import QpFormContainer from '../../quickPrompt/containers/QpFormContainer';
import ThreadContainer from '../../thread/containers/ThreadContainer';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@/store';
import { ArrowBack } from '@mui/icons-material';
import { clearCurrentThread } from '../../thread/thread.slice';
import { useTranslation } from 'react-i18next';

interface Step4ConfirmationProps {
  step4Data: QpEditStep4Data;
  isEditMode: boolean;
  isScreenDownMd: boolean;
  isScreenDownSm: boolean;
}

function QpEditStep4ConfirmationContainer({
  step4Data,
  isEditMode,
  isScreenDownMd,
  isScreenDownSm,
}: Step4ConfirmationProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const currentThread = useSelector((state: RootState) => state.thread.currentThread);

  if (!step4Data.confirmQp) {
    return;
  }

  const showLeftArea = (
    // mdではない
    !isScreenDownMd ||
    // またはスレッドがない
    !currentThread
  );

  const showRightArea = (
    // md以上
    !isScreenDownMd ||
    // かつスレッドがある
    currentThread
  );

  return (
    <Box
      display="flex"
      flexDirection={isScreenDownMd ? 'column' : 'row'}
      p={2}
      sx={{
        flexGrow: 1,
        overflow: 'auto',
        height: '100%'
      }}
    >
      <Box
        flex={1}
        display={ showLeftArea ? "flex" : "none" }
        flexDirection="column"
        pr={isScreenDownMd ? 0 : 1}
      >
        <Typography variant="h6" gutterBottom>
          {
            isScreenDownSm
              ? (
                /* t:4. 動作確認 */
                t("quickPromptEdit:step4.miniHeading")
              ) : isEditMode
                ? (
                  /* t:更新後のクイックプロンプト */
                  t("quickPromptEdit:step4.headingAfterUpdate")
                ) : (
                  /* t:作成されるクイックプロンプト */
                  t("quickPromptEdit:step4.headingAfterCreate")
                )
          }
        </Typography>
        <Box flex={1} border={1} borderColor="grey.300" p={2}>
          <QpFormContainer
            quickPromptOrId={step4Data.confirmQp}
            isTestMode={true}
          />
        </Box>
      </Box>

      {showRightArea && (
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          pl={ !isScreenDownMd ? 4 : 2 }
          ml={ !isScreenDownMd ? 3 : 0 }
          borderLeft={ !isScreenDownMd ? `1px solid ${theme.palette.divider}` : "none" }
        >
          {
            isScreenDownMd && (
              <Box>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    dispatch(clearCurrentThread());
                  }}
                  startIcon={<ArrowBack />}
                >
                  {/* t:入力し直す */}
                  {t("quickPromptEdit:step4.inputAgain")}
                </Button>
              </Box>
            )
          }
          <Typography variant="h6" gutterBottom>
            {/* t:スレッドシュミレーション */}
            {t("quickPromptEdit:step4.threadSimulation")}
          </Typography>
          <Box
            flex={1}
            border={1}
            borderColor="grey.300"
            p={2}
            flexGrow={1}
          >
            {
              currentThread
                ? <ThreadContainer isTest={true} />
                : (
                  <Typography variant="body2" color="textSecondary">
                    {/* t:クイックプロンプトの入力および送信を行い、どのようなスレッドが作成されるか試してみましょう。 */}
                    {t("quickPromptEdit:step4.noThreadMessage")}
                  </Typography>
                )
            }
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default QpEditStep4ConfirmationContainer;
