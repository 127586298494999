import { AIMessage, MessageNode, SseEventBlockedByDlp, Thread, UserMessage } from "./thread.type";
import { ThreadDlpReplace } from "../dlp/dlp.type";
import { TFunction } from "i18next";

export const getMessageNode = (
  thread: Thread,
  message?: UserMessage | AIMessage,
): MessageNode | undefined => {
  if (!message) return undefined;
  const userMsgNode = thread.messageNodes.find((node) => {
    return (
      (node.userMessage && node.userMessage.id === message.id) ||
      (node.aiMessages && node.aiMessages.find((aiMsg) => aiMsg.id === message.id))
    );
  });
  return userMsgNode;
}

export const getParentNode = (
  thread: Thread,
  node?: MessageNode,
): MessageNode | undefined => {
  if (!node) return undefined;
  const parentNodeId = node.path[node.path.length - 1];
  const parentNode = thread.messageNodes.find((node) => node.id === parentNodeId);
  return parentNode;
}

export const getChildrenNodes = (
  thread: Thread,
  node?: MessageNode,
): MessageNode[] => {
  if (!node) return [];
  return thread.messageNodes
    .filter((n) => n.path[n.path.length - 1] === node.id)
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
}

export const getAncestorNodes = (
  thread: Thread,
  node?: MessageNode,
): MessageNode[] => {
  if (!node) return [];
  return thread.messageNodes
    .filter((n) => n.path.includes(node.id))
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
}


export const getLatestLeafNode = (
  thread: Thread,
  node?: MessageNode,
): MessageNode | undefined => {
  if (!node) return undefined;
  const children = getChildrenNodes(thread, node);
  if (children.length === 0) return node;
  return getLatestLeafNode(thread, children[children.length - 1]);
}


export const handleDlpCheck = (
  t: TFunction,
  event: SseEventBlockedByDlp,
  postAction: (skipDlpBlock: boolean) => void,
  setErrorModal: (title: string, message: string) => void,
  setConfirmModal: (title: string, message: string, onConfirm?: () => void, onCancel?: () => void) => void
) => {
  const result = event.result;
  const userInput = event.userInput;
  if (result.prohibitedSummaries.length > 0) {

    // クイックプロンプトそのものの内容に問題がある
    const isQpProhibited = result.prohibitedSummaries.some(summary => {
      return summary.expressions.some(expression => {
        return !userInput.includes(expression);
      });
    });

    // t:以下の内容が禁止されているワードとして検知されました。
    let msg = t("thread:dlp.prohibited.message");
    if(isQpProhibited) {
      // t: ※ クイックプロンプトの定義自体に禁止ワードが含まれています。
      //      クイックプロンプトの作成者に修正するようご連絡ください。
      msg += "\n\n" + t("thread:dlp.prohibited.qpMessage");
    }
    msg += "\n\n";
    result.prohibitedSummaries.forEach(summary => {
      msg += `[${summary.configName}]\n`;
      msg += `${summary.expressions.join(', ')}\n\n`;
    });
    if (result.warnedSummaries.length > 0) {
      msg += "\n";
      // t:また下記の内容は警告対象のワードとなりますので、\nこちらも併せてご確認ください。
      msg += t("thread:dlp.warned.additionalInfo") + "\n\n";
      result.warnedSummaries.forEach(summary => {
        msg += `[${summary.configName}]\n`;
        msg += `${summary.expressions.join(', ')}\n\n`;
      });
    }

    setErrorModal(
      // t:禁止されているワードを検知しました
      t('thread:dlp.prohibited.title'),
      msg
    );
  }
  else if (result.warnedSummaries.length > 0) {

    // クイックプロンプトそのものの内容に問題がある
    const isQpWarned = result.warnedSummaries.some(summary => {
      return summary.expressions.some(expression => {
        return !userInput.includes(expression);
      });
    });
    // t:以下の内容は警告対象のワードとなります。本当に投稿してよろしいですか？
    let msg = t('thread:dlp.warned.message');
    if (isQpWarned) {
      // t: ※ クイックプロンプトの定義自体に警告ワードが含まれています。
      //      警告を消したい場合は作成者に修正するようご連絡ください。
      msg += "\n\n" + t('thread:dlp.warned.qpMessage');
    }
    msg += (
      "\n\n" +
      result.warnedSummaries.map(summary => {
        return `[${summary.configName}]\n${summary.expressions.join(', ')}\n\n`;
      }).join('')
    );

    setConfirmModal(
      // t:注意が必要なワードが含まれています
      t('thread:dlp.warned.title'),
      msg,
      () => postAction(true),
    );
  }
};

export const restoreMask = (maskedContents: string, dlpReplaces: ThreadDlpReplace[]) => {
  let restoredContents = maskedContents;
  if (!dlpReplaces || dlpReplaces.length === 0 || !maskedContents) {
    return restoredContents;
  }
  dlpReplaces.forEach((replace) => {
    const maskText = `{{${replace.maskPrefix}:${replace.no}}}`;
    const regex = new RegExp(maskText, 'g');
    restoredContents = restoredContents.replace(regex, replace.originText);
  });
  return restoredContents;
}
