import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import QpListContainer from '../../quickPrompt/containers/QpListContainer';
import styled from "@emotion/styled";
import { History, MoreVert } from '@mui/icons-material';
import { LeftSideBarWrapper, LeftSidebarSubTitle } from '@/components/ui/StyledComponents';
import { useTranslation } from 'react-i18next';
import QpVisibilityManagementModal from '../../quickPromptManagement/containers/QpVisibilityManagementModal';
import QpCategoryCreateModal from '../../quickPromptManagement/containers/QpCategoryCreateModal';

const ButtonWrapper = styled.div`
  padding: 10px;
`;
const CreateThreadButton = styled(Button)`
  box-shadow: none;
  border-radius: 30px;
`;

const ThreadLeftSidebarContainer: React.FC<{ onItemClick: () => void }> = ({ onItemClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [manageVisibilityModalOpen, setManageVisibilityModalOpen] = useState(false);
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // クイックプロンプト作成
  const handleQuickPromptCreate = () => {
    navigate(`/quick-prompts/new`);
    handleMenuClose();
  };

  // カテゴリ作成 (Dialogを開く)
  const handleCategoryCreateClick = () => {
    handleMenuClose();
    setCategoryDialogOpen(true);
  };

  // 表示/非表示管理
  const handleManageVisibilityClick = () => {
    handleMenuClose();
    setManageVisibilityModalOpen(true);
  };

  return (
    <LeftSideBarWrapper sx={{marginBottom: 10}}>
      <ButtonWrapper>
        <CreateThreadButton variant="contained" color="secondary"
          fullWidth onClick={ () => { navigate(`/`); onItemClick(); } }>
          {/* t:新規スレッド */}
          {t("thread:newThread")}
        </CreateThreadButton>
      </ButtonWrapper>
      <List component="div" disablePadding>
        <ListItemButton sx={{ pl: 1.5 }} onClick={ () => { navigate(`/threads`); onItemClick(); } }>
          <ListItemIcon sx={{ minWidth: 31 }}>
            <History /> {/* アイコンをレンダリング */}
          </ListItemIcon>
          <ListItemText sx={{ m: 0, '& .MuiListItemText-primary': { fontSize: '0.875rem' } }}
            // t:スレッド履歴
            primary={t("thread:threadHistory")} />
        </ListItemButton>
      </List>
      <Divider sx={{ mt: 1.1, mb: 0.8 }}/>
      {/* t:クイックプロンプト */}
      <LeftSidebarSubTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {t("thread:quickPrompt.title")}
        <Box sx={{pr:1.2}}>
          <IconButton size="small" onClick={handleMenuClick}>
            <MoreVert fontSize="inherit" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleQuickPromptCreate}>{
              // t: クイックプロンプト作成
              t("thread:quickPrompt.actions.createQuickPrompt")
            }</MenuItem>
            <MenuItem onClick={handleCategoryCreateClick}> {
              // t: カテゴリ作成
              t("thread:quickPrompt.actions.createQpCategory")
            } </MenuItem>
            <MenuItem onClick={handleManageVisibilityClick}>{
              // t: 表示/非表示の管理
              t("thread:quickPrompt.actions.manageVisibility")
            }</MenuItem>
          </Menu>
        </Box>
      </LeftSidebarSubTitle>

      <QpListContainer></QpListContainer>

      <QpVisibilityManagementModal
        open={manageVisibilityModalOpen}
        onClose={() => setManageVisibilityModalOpen(false)}
      />

      {/* カテゴリ作成ダイアログ */}
      <QpCategoryCreateModal
        open={categoryDialogOpen}
        onClose={() => setCategoryDialogOpen(false)}
      />

    </LeftSideBarWrapper>
  );
};

export default ThreadLeftSidebarContainer;
