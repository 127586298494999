import { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Paper, Typography, Button } from '@mui/material';
import { QpEditStep1Data, ReqQpPropertyEdit } from '../qpEditForm.type';
import apiAxios from '@/lib/axios';
import { QpAuthorityType } from '../../quickPrompt/quickPrompt.constant';
import QpFormPresenter from '../../quickPrompt/presenters/QpFormPresenter';
import { QpPropertyType, QuickPromptWithVersion } from '../../quickPrompt/quickPrompt.type';
import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';

interface Props {
  step1Data: QpEditStep1Data;
  properties: ReqQpPropertyEdit[];
}

function QpEditStep2PreviewContainer({ step1Data, properties }: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [formSchema, setFormSchema] = useState<any>(null);
  const [uiSchema, setUiSchema] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  // スクロール位置を保持するためのref
  const previewContainerRef = useRef<HTMLDivElement>(null);
  // プレビュー更新前のスクロール位置を保持
  const scrollPositionRef = useRef<number>(0);

  const fetchPreview = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiAxios.post('/quick-prompts/preview-form', {
        description: step1Data.description,
        properties,
      });
      setFormSchema(response.data.formSchema);
      setUiSchema(response.data.uiSchema);
    } catch (error) {
      console.error(error);
      captureException(error);
      // t:プレビューの取得に失敗しました。もう一度お試しください。
      setError(t("quickPromptEdit:messages.previewFetchError"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // スクロール位置を保存
    if (previewContainerRef.current) {
      scrollPositionRef.current = previewContainerRef.current.scrollTop;
    }

    fetchPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties]);

  // 再度スクロール位置を復元
  useEffect(() => {
    if (!loading && previewContainerRef.current) {
      previewContainerRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [loading, formSchema, uiSchema]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight={200}>
        <Typography variant="body1" color="error" gutterBottom>
          {error}
        </Typography>
        <Button variant="contained" color="primary" onClick={fetchPreview}>
          {/* t:再試行 */}
          {t('common:button.retry')}
        </Button>
      </Box>
    );
  }

  if (!formSchema || !uiSchema) {
    return null;
  }

  const dummyQp: QuickPromptWithVersion = {
    id: '',
    name: step1Data.name || "",
    description: step1Data.description,
    shortName: step1Data.shortName,
    shortDescription: step1Data.shortDescription,
    icon: step1Data.icon,
    createdAt: '',
    updatedAt: '',
    quickPromptSetId: '',
    authorityType: QpAuthorityType.USER,
    isOfficial: false,
    isShared: false,
    latestVersion: {
      id: '',
      formSchema,
      uiSchema,
      promptTemplate: '',
      createdAt: '',
    },
  };

  const dummyFormData: any = {};
  const toggleAdvanceSettingsProp = properties.find(p => p.type === QpPropertyType.TOGGLE_ADVANCED_SETTING);
  if (toggleAdvanceSettingsProp) {
    const key = toggleAdvanceSettingsProp.key;
    dummyFormData[key] = true;
  }

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        pr: 3,
        mb: 2,
        flexGrow: 1,
        overflow: 'auto',
        height: 'calc(100% - 40px)'
      }}
      // スクロールを追跡
      ref={previewContainerRef}
    >
      <QpFormPresenter
        loading={false}
        qp={dummyQp}
        formData={dummyFormData}
        initialFile={undefined}
        selectedFile={undefined}
        onSelectFile={() => {}}
        onGenerating={false}
        optionOpened={false}
        showTrySend={false}
        hideSendButton={true}
        hideEditMenu={true}
        handleFormChange={() => {}}
        handleSendMessage={() => {}}
      />
    </Paper>
  );
}

export default QpEditStep2PreviewContainer;
