import { useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
} from '@mui/material';
import { ReqQpPropertyEdit } from '../qpEditForm.type';
import { QpPropertyType, ReqQpPropertyDetail } from '../../quickPrompt/quickPrompt.type';

// 画像をインポート
import TextFieldImage from '@/assets/images/qpEditForm/TextField.png';
import TextAreaImage from '@/assets/images/qpEditForm/TextArea.png';
import TextAreaWithToolbarImage from '@/assets/images/qpEditForm/TextAreaWithToolbar.png';
import RadioButtonImage from '@/assets/images/qpEditForm/RadioButton.png';
import SelectBoxImage from '@/assets/images/qpEditForm/SelectBox.png';
import CheckBoxImage from '@/assets/images/qpEditForm/CheckBox.png';
import SingleCheckBoxImage from '@/assets/images/qpEditForm/SingleCheckBox.png';
import SelectAiModelsImage from '@/assets/images/qpEditForm/SelectAiModels.png';
import ToggleAdvancedSettingImage from '@/assets/images/qpEditForm/ToggleAdvancedSetting.png';
import { generatePropertyKey } from '../qpEditForm.utils';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentMembership } from '../../auth/auth.type';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: () => void;
  onAddItem: (item: ReqQpPropertyEdit) => void;
  existingProperties: ReqQpPropertyEdit[];
}

function QpEditStep2AddItemModal({
  open,
  onClose,
  onAddItem,
  existingProperties
}: Props) {
  const { t } = useTranslation();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const membership = getCurrentMembership(loginUser);
  const teamConfig = membership?.team?.config;
  const enableTextAreaWithToolbar = teamConfig?.isFileAttachmentEnabled;

  const componentList = [
    {
      id: 'textField',
      /* t:テキストフィールド */
      name: t("quickPromptEdit:step2.addModal.textFieldName"),
      /* t:短い文字列を入力するための入力項目です。 */
      description: t("quickPromptEdit:step2.addModal.textFieldDescription"),
      image: TextFieldImage,
    },
    {
      id: 'textArea',
      /* t:テキストエリア */
      name: t("quickPromptEdit:step2.addModal.textAreaName"),
      /* t:長い文章を入力するための入力項目です。 */
      description: t("quickPromptEdit:step2.addModal.textAreaDescription"),
      image: TextAreaImage,
    },
    {
      id: 'radioButton',
      /* t:ラジオボタン */
      name: t("quickPromptEdit:step2.addModal.radioButtonName"),
      /* t:選択肢から一つだけ選択できる入力項目です。 */
      description: t("quickPromptEdit:step2.addModal.radioButtonDescription"),
      image: RadioButtonImage,
    },
    {
      id: 'selectBox',
      /* t:セレクトボックス */
      name: t("quickPromptEdit:step2.addModal.selectBoxName"),
      /* t:選択肢から一つだけ選択できる入力項目です。 */
      description: t("quickPromptEdit:step2.addModal.selectBoxDescription"),
      image: SelectBoxImage,
    },
    {
      id: 'checkBox',
      /* t:チェックボックス */
      name: t("quickPromptEdit:step2.addModal.checkBoxName"),
      /* t:選択肢から複数選択できる入力項目です。 */
      description: t("quickPromptEdit:step2.addModal.checkBoxDescription"),
      image: CheckBoxImage,
    },
    {
      id: 'singleCheckBox',
      /* t:単一チェックボックス */
      name: t("quickPromptEdit:step2.addModal.singleCheckBoxName"),
      /* t:オン/オフを切り替えられる入力項目です。 */
      description: t("quickPromptEdit:step2.addModal.singleCheckBoxDescription"),
      image: SingleCheckBoxImage,
    },
    ...(enableTextAreaWithToolbar
      ? [
          {
            id: 'textAreaWithToolbar',
            /* t:多機能テキストエリア */
            name: t("quickPromptEdit:step2.addModal.textAreaWithToolbarName"),
            /* t:文字入力だけでなく、ファイルアップロード等もできる入力項目です。フォームに一つだけ追加可能です。 */
            description: t("quickPromptEdit:step2.addModal.textAreaWithToolbarDescription"),
            image: TextAreaWithToolbarImage,
          },
        ]
      : []),
    {
      id: 'selectAiModels',
      /* t:AIモデル選択 */
      name: t("quickPromptEdit:step2.addModal.selectAiModelsName"),
      /* t:AIモデルの一覧から複数選択できます。フォームに一つだけ追加可能です。 */
      description: t("quickPromptEdit:step2.addModal.selectAiModelsDescription"),
      image: SelectAiModelsImage,
    },
    {
      id: 'toggleAdvancedSetting',
      /* t:詳細設定の開閉 */
      name: t("quickPromptEdit:step2.addModal.toggleAdvancedSettingName"),
      /* t:詳細設定を開いている時のみ下の項目を表示します。フォームに一つだけ追加可能です。 */
      description: t("quickPromptEdit:step2.addModal.toggleAdvancedSettingDescription"),
      image: ToggleAdvancedSettingImage,
    },
  ];

  const existingTypes = useMemo(
    () => existingProperties.map((prop) => prop.type),
    [existingProperties]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      {/* t:項目を選択 */}
      <DialogTitle>{t("quickPromptEdit:step2.addModal.pickItemTitle")}</DialogTitle>
      <DialogContent
        sx={{
          // Mac などのOSでスクロールバーが非表示にならないようにカスタムスクロールバーを適用
          '::-webkit-scrollbar': {
            width: '8px',
          },
          '::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '4px',
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px',
          },
          '::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        }}
      >
        <Grid container spacing={2}>
          {componentList.map((component) => {
            const isDisabled =
              (component.id === 'textAreaWithToolbar' &&
                existingTypes.includes(QpPropertyType.TEXT_AREA_WITH_TOOLBAR)) ||
              (component.id === 'selectAiModels' &&
                existingTypes.includes(QpPropertyType.SELECT_AI_MODELS)) ||
              (component.id === 'toggleAdvancedSetting' &&
                existingTypes.includes(QpPropertyType.TOGGLE_ADVANCED_SETTING));

            return (
              <Grid item xs={6} sm={4} md={3} key={component.id}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                  }}
                >
                  <CardActionArea
                    onClick={() => {
                      if (!isDisabled) {
                        const newProperty = createNewProperty(component.id, existingProperties, t);
                        onAddItem(newProperty);
                      }
                    }}
                    disabled={isDisabled}
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      height: '100%',
                      position: 'relative',
                      ...(isDisabled && {
                        /* t:既に追加済みです(フォームに1つのみ追加可) */
                        '&::after': {
                          content: `"${t("quickPromptEdit:step2.addModal.itemAlreadyAdded")}"`,
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          color: 'red',
                          textAlign: 'center',
                          whiteSpace: 'pre',
                          fontWeight: 'bold',
                          zIndex: 1000,
                        },
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          zIndex: 500,
                        },
                      }),
                    }}
                  >
                    <CardContent sx={{ width: '100%', zIndex: 2 }}>
                      <Typography variant="body1" gutterBottom>
                        {component.name}
                      </Typography>
                      <CardMedia
                        component="img"
                        sx={{ objectFit: 'contain', height: 140, width: '100%' }}
                        image={component.image}
                        alt={component.name}
                      />
                      <Typography variant="caption" color="textSecondary">
                        {component.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        {/* t:閉じる (共通ボタン) */}
        <Button onClick={onClose}>{t("common:button.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}

// t:新しいプロパティを生成する
function createNewProperty(
  type: string,
  existingProperties: ReqQpPropertyEdit[],
  t: any
): ReqQpPropertyEdit {
  const id = Date.now().toString();
  const qpType = componentTypeMap[type];
  // t:ラベル生成
  const label = getLabel(type, existingProperties, t);
  const key = generatePropertyKey(label);
  return {
    id,
    key,
    label,
    type: qpType,
    isAdvancedMode: false,
    detail: getDefaultDetail(type, t),
  };
}

const componentTypeMap: { [key: string]: QpPropertyType } = {
  textField: QpPropertyType.TEXT_FIELD,
  textArea: QpPropertyType.TEXT_AREA,
  checkBox: QpPropertyType.CHECK_BOX,
  radioButton: QpPropertyType.RADIO_BUTTON,
  selectBox: QpPropertyType.SELECT_BOX,
  singleCheckBox: QpPropertyType.SINGLE_CHECK_BOX,
  textAreaWithToolbar: QpPropertyType.TEXT_AREA_WITH_TOOLBAR,
  selectAiModels: QpPropertyType.SELECT_AI_MODELS,
  toggleAdvancedSetting: QpPropertyType.TOGGLE_ADVANCED_SETTING,
};

/**
 * 新しいプロパティのラベルを生成する
 *
 * @param type - プロパティのタイプ ('textField', 'textArea' など)
 * @param existingProperties - 既存のプロパティ配列
 * @returns 生成されたラベル文字列
 *
 * @example
 * // 既存のプロパティがない場合
 * getLabel('textField', []) // => 'テキストフィールド'
 *
 * // 既存のプロパティがある場合は連番を付与
 * getLabel('textField', [{label: 'テキストフィールド'}]) // => 'テキストフィールド2'
 *
 * // 既に連番がある場合は次の番号を付与
 * getLabel('textField', [
 *   {label: 'テキストフィールド'},
 *   {label: 'テキストフィールド2'}
 * ]) // => 'テキストフィールド3'
 *
 * // 欠番がある場合は最大の番号+1を付与
 * getLabel('textField', [
 *   {label: 'テキストフィールド'},
 *   {label: 'テキストフィールド3'}
 * ]) // => 'テキストフィールド4'
 */
function getLabel(
  type: string,
  existingProperties: ReqQpPropertyEdit[],
  t: any
): string {
  const baseLabel = (() => {
    switch (type) {
      case 'textField':
        return t("quickPromptEdit:step2.addModal.textFieldName");
      case 'textArea':
        return t("quickPromptEdit:step2.addModal.textAreaName");
      case 'textAreaWithToolbar':
        return t("quickPromptEdit:step2.addModal.textAreaWithToolbarName");
      case 'checkBox':
        return t("quickPromptEdit:step2.addModal.checkBoxName");
      case 'radioButton':
        return t("quickPromptEdit:step2.addModal.radioButtonName");
      case 'selectBox':
        return t("quickPromptEdit:step2.addModal.selectBoxName");
      case 'singleCheckBox':
        return t("quickPromptEdit:step2.addModal.singleCheckBoxName");
      case 'selectAiModels':
        return t("quickPromptEdit:step2.addModal.selectAiModelsName");
      case 'toggleAdvancedSetting':
        return t("quickPromptEdit:step2.addModal.toggleAdvancedSettingName");
      default:
        return '';
    }
  })();

  if (!baseLabel) return '';

  // 既存のラベルから同じベースラベルを持つものを抽出し、番号を取得
  const existingNumbers = existingProperties
    .map((p) => p.label)
    .filter((label) => label.startsWith(baseLabel))
    .map((label) => {
      const match = label.match(new RegExp(`^${baseLabel}(\\d+)?$`));
      return match ? (match[1] ? parseInt(match[1]) : 1) : 0;
    })
    .filter((num) => num > 0);

  // 既存のラベルがない場合はベースラベルをそのまま返す
  if (existingNumbers.length === 0) {
    return baseLabel;
  }

  // 最大の番号を取得し、その次の番号を付与
  const maxNumber = Math.max(...existingNumbers);
  return `${baseLabel}${maxNumber + 1}`;
}

// t:デフォルト詳細を取得
function getDefaultDetail(type: string, t: any): ReqQpPropertyDetail {
  switch (type) {
    case 'textField':
      return {
        textField: {
          defaultValue: '',
          isRequired: false,
          maxLength: 100,
          placeholder: '',
          helpText: '',
        },
      };
    case 'textArea':
      return {
        textArea: {
          defaultValue: '',
          isRequired: false,
          maxLength: 10000,
          placeholder: '',
          helpText: '',
          rows: 5,
        },
      };
    case 'textAreaWithToolbar':
      return {
        textAreaWithToolbar: {
          defaultValue: '',
          isRequired: false,
          maxLength: 10000,
          placeholder: '',
          helpText: '',
          rows: 5,
        },
      };
    case 'checkBox':
      return {
        checkBox: {
          isRequired: false,
          helpText: '',
          options: [
            {
              // t:おまかせ
              value: t("quickPromptEdit:step2.defaultOption.omakase"),
              label: t("quickPromptEdit:step2.defaultOption.omakase"),
              helpText: '',
              isDefault: true,
            },
            {
              // t:選択肢1
              value: t("quickPromptEdit:step2.defaultOption.option1"),
              label: t("quickPromptEdit:step2.defaultOption.option1"),
              helpText: '',
              isDefault: false,
            },
            {
              // t:選択肢2
              value: t("quickPromptEdit:step2.defaultOption.option2"),
              label: t("quickPromptEdit:step2.defaultOption.option2"),
              helpText: '',
              isDefault: false,
            },
          ],
          isInline: false,
        },
      };
    case 'radioButton':
      return {
        radioButton: {
          isRequired: false,
          helpText: '',
          options: [
            {
              // t:おまかせ
              value: t("quickPromptEdit:step2.defaultOption.omakase"),
              label: t("quickPromptEdit:step2.defaultOption.omakase"),
              helpText: '',
              isDefault: true,
            },
            {
              // t:選択肢1
              value: t("quickPromptEdit:step2.defaultOption.option1"),
              label: t("quickPromptEdit:step2.defaultOption.option1"),
              helpText: '',
              isDefault: false,
            },
            {
              // t:選択肢2
              value: t("quickPromptEdit:step2.defaultOption.option2"),
              label: t("quickPromptEdit:step2.defaultOption.option2"),
              helpText: '',
              isDefault: false,
            },
          ],
          isInline: false,
        },
      };
    case 'selectBox':
      return {
        selectBox: {
          isRequired: false,
          helpText: '',
          options: [
            {
              // t:おまかせ
              value: t("quickPromptEdit:step2.defaultOption.omakase"),
              label: t("quickPromptEdit:step2.defaultOption.omakase"),
              helpText: '',
              isDefault: true,
            },
            {
              // t:選択肢1
              value: t("quickPromptEdit:step2.defaultOption.option1"),
              label: t("quickPromptEdit:step2.defaultOption.option1"),
              helpText: '',
              isDefault: false,
            },
            {
              // t:選択肢2
              value: t("quickPromptEdit:step2.defaultOption.option2"),
              label: t("quickPromptEdit:step2.defaultOption.option2"),
              helpText: '',
              isDefault: false,
            },
          ],
        },
      };
    case 'singleCheckBox':
      return {
        singleCheckBox: {
          isRequired: false,
          helpText: '',
          defaultValue: false,
        },
      };
    case 'selectAiModels':
      return {
        selectAiModels: {
          isRequired: false,
          helpText: '',
          defaultModels: [],
        },
      };
    case 'toggleAdvancedSetting':
      return {
        toggleAdvancedSetting: {},
      };
    default:
      return {};
  }
}

export default QpEditStep2AddItemModal;
