import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Select,
  MenuItem,
  FormControl,
  Box,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  TextField,
  CircularProgress,
  Tooltip,
  Divider,
  FormLabel,
} from '@mui/material';
import { Close, Delete } from '@mui/icons-material';
import { RootState, useAppDispatch } from '@/store';
import { showUnknownErrorModal } from '../../generic/errorModal.slice';
import { captureException } from '@sentry/react';
import { showToast } from '../../generic/toast.slice';
import { QuickPrompt, QuickPromptSet } from '../../quickPrompt/quickPrompt.type';
import { Team } from '../../team/team.type';
import { MembershipGroup } from '../../membership-group/membership-group.type';
import { MembershipWithUser } from '../../membership/membership.type';
import apiAxios from '@/lib/axios';
import { QpAuthorityType, QuickPromptSetType } from '../../quickPrompt/quickPrompt.constant';
import { QpAuthority } from '../quickPromptManagement.type';
import { useConfirmModal } from '../../generic/hooks/useConfirmModal';
import { fetchQuickPromptSetsForced, setIsSharedForQp, setIsSharedForQpSet } from '../../quickPrompt/quickPrompt.slice';
import { useSelector } from 'react-redux';
import { getCurrentMembership, hasAdminAuth, hasSuperAdminAuth } from '../../auth/auth.type';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  sharedObject: QuickPrompt | QuickPromptSet;
  userAuthority: QpAuthorityType;
  team: Team;
  open: boolean;
  onClose: () => void;
}

const QpShareModal: React.FC<Props> = ({
  sharedObject,
  userAuthority,
  team,
  open,
  onClose
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setConfirmModal } = useConfirmModal();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const [authorities, setAuthorities] = useState<QpAuthority[]>([]);
  const [readOnlyAuthorities, setReadOnlyAuthorities] = useState<QpAuthority[]>([]);
  const [isFailedGetReadOnlyAuthorities, setIsFailedGetReadOnlyAuthorities] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const { quickPromptSets } = useSelector((state: RootState) => state.quickPrompt);

  const [targetType, setTargetType] = useState<'team' | 'group' | 'member'>('member');
  const [authorityType, setAuthorityType] = useState<QpAuthorityType>(QpAuthorityType.USER);
  const [groups, setGroups] = useState<MembershipGroup[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [members, setMembers] = useState<MembershipWithUser[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isLoadingGroups, setIsLoadingGroups] = useState<boolean>(false);
  const [isLoadingMembers, setIsLoadingMembers] = useState<boolean>(false);

  const { t } = useTranslation();

  const isQpSet = !('quickPromptSetId' in sharedObject);
  const authApiPrefix =
    isQpSet
      ? `/quick-prompt-sets/${sharedObject.id}/authorities`
      : `/quick-prompts/${sharedObject.id}/authorities`;

  const membership = getCurrentMembership(loginUser);
  const isSuperAdmin = hasSuperAdminAuth(loginUser);
  const isAdmin = hasAdminAuth(loginUser);

  const concatAuthorities = [...authorities, ...readOnlyAuthorities];

  const unclassifiedQpSet = quickPromptSets.find(
    set => set.type === QuickPromptSetType.UNCLASSIFIED
  );

  const isOwner = userAuthority === QpAuthorityType.OWNER;
  const isEditor = userAuthority === QpAuthorityType.EDITOR;

  const handleForbiddenRedirect = () => {
    // t:権限が変更されました。
    dispatch(showToast({ message: t('quickPromptManagement:shareModal.messages.forbiddenRedirect'), severity: 'success' }));
    dispatch(fetchQuickPromptSetsForced());
    onClose();
    navigate('/');
  };

  const checkForbiddenAndRedirect = async () => {
    try {
      await apiAxios.get(authApiPrefix);
    } catch (error: any) {
      if (error?.response?.status === 403) {
        handleForbiddenRedirect();
        return true;
      }
    }
    return false;
  };

  // 権限一覧とグループ一覧を同時に取得
  useEffect(() => {
    if (open) {
      fetchDataAndGroups();
    }
  }, [open]);

  const fetchDataAndGroups = async () => {
    setIsLoading(true);
    setIsLoadingGroups(true);
    try {
      const [authorityResponse, groupResponse, parentSetAuthResponse] = await Promise.all([
        apiAxios.get(authApiPrefix),
        isAdmin ? apiAxios.get('/membership-groups', {
          params: {
            offset: 0,
            limit: 999,
            orderBy: 'created_at',
            order: 'desc',
          },
        }) : Promise.resolve(null),
        // QPの場合は親のQPセットの権限も取得。ただし403時はエラー扱いせずにnull返却＆フラグON
        !isQpSet
          ? apiAxios
              .get(`/quick-prompt-sets/${(sharedObject as QuickPrompt).quickPromptSetId}/authorities`)
              .catch((err) => {
                if (err.response?.status === 403) {
                  setIsFailedGetReadOnlyAuthorities(true);
                  return null; // 403は握り潰す
                }
                throw err; // その他のエラーは再スロー
              })
          : Promise.resolve(null),
      ]);

      // 対象のQPまたはQPセットの権限をセット
      const authorities = authorityResponse.data;
      setAuthorities(authorities);

      // 管理者の場合、グループ一覧をセット (新規権限追加用)
      if (groupResponse) {
        setGroups(groupResponse.data.membershipGroups);
      }

      // 対象がQPの場合はその親のQPセットの権限も取得する
      if (parentSetAuthResponse) {
        // 重複している権限については除外
        const filteredAuthorities = (parentSetAuthResponse?.data || []).filter(
          (parentAuth: QpAuthority) => !authorities.some(
            (auth: QpAuthority) => auth.subjectId === parentAuth.subjectId
          )
        );
        setReadOnlyAuthorities(filteredAuthorities);
      }

    } catch (error) {
      console.debug(error);
      captureException(error);
      dispatch(showUnknownErrorModal());
      setAuthorities([]);
      setGroups([]);
    } finally {
      setIsLoading(false);
      setIsLoadingGroups(false);
    }
  };

  // 唯一のオーナーか？
  const isLastOwner = (authorityId: string) => {
    if (!isQpSet) {
      return false;
    }
    const targetAuthority = authorities.find((auth) => auth.id === authorityId);
    return (
      targetAuthority?.authorityType === QpAuthorityType.OWNER &&
      authorities.filter((auth) => auth.authorityType === QpAuthorityType.OWNER).length === 1
    );
  };

  // 権限の変更
  const handleAuthorityChange = async (authorityId: string, newAuthorityType: QpAuthorityType) => {
    try {
      // 唯一のオーナーは変更できない
      if (isLastOwner(authorityId)) {
        dispatch(showToast({ message: t('quickPromptManagement:shareModal.messages.cannotChangeSoleOwner'), severity: 'error' }));
        return;
      }

      setIsLoading(true);
      await apiAxios.put(`${authApiPrefix}/${authorityId}`, {
        authorityType: newAuthorityType,
      });

      if (await checkForbiddenAndRedirect()) return;

      // t:権限を更新しました
      dispatch(showToast({ message: t('quickPromptManagement:shareModal.messages.authorityUpdated'), severity: 'success' }));
      fetchDataAndGroups();
    } catch (error) {
      console.debug(error);
      captureException(error);
      dispatch(showUnknownErrorModal());
    } finally {
      setIsLoading(false);
    }
  };

  // 削除
  const handleDeleteAuthority = async (authorityId: string) => {
    // 唯一のオーナーは削除できない
    if (isLastOwner(authorityId)) {
      // t:唯一のオーナーの権限は削除できません
      dispatch(showToast({ message: t('quickPromptManagement:shareModal.messages.cannotChangeSoleOwner'), severity: 'error' }));
      return;
    }

    const deletedAuthority = authorities.find((auth) => auth.id === authorityId);
    const authorityTypeLabel = deletedAuthority?.authorityType === 'owner'
      ? 'オーナー'
      : deletedAuthority?.authorityType === 'editor'
      ? '編集者'
      : '利用者'
    ;

    const deleteAuth = async () => {
      try {
        setIsLoading(true);
        const res = await apiAxios.delete(`${authApiPrefix}/${authorityId}`);

        const isShared = res.data['isShared'] || false;
        if (isQpSet) {
          dispatch(setIsSharedForQpSet({id: sharedObject.id, isShared}));
        } else {
          dispatch(setIsSharedForQp({id: sharedObject.id, isShared} ));
        }

        if (await checkForbiddenAndRedirect()) return;

        // t:権限を削除しました
        dispatch(showToast({ message: t('quickPromptManagement:shareModal.messages.authorityDeleted'), severity: 'success' }));
        fetchDataAndGroups();
      } catch (error) {
        console.debug(error);
        captureException(error);
        dispatch(showUnknownErrorModal());
      } finally {
        setIsLoading(false);
      }
    };

    // t:権限削除確認
    // t:下記の権限を削除してよろしいですか？
    //   {{subjectName}}
    //   ({{authorityTypeLabel}})
    setConfirmModal(
      t('quickPromptManagement:shareModal.messages.deleteTitle'),
      t('quickPromptManagement:shareModal.messages.deleteConfirm', {
        subjectName: deletedAuthority?.subjectName,
        authorityTypeLabel
      }),
      deleteAuth,
    );
  };

  // メンバー検索
  useEffect(() => {
    if (targetType === 'member') {
      fetchMembers();
    }
  }, [targetType, searchQuery]);

  const fetchMembers = async () => {
    setIsLoadingMembers(true);
    try {
      const response = await apiAxios.get('/memberships', {
        params: {
          offset: 0,
          limit: 50,
          orderBy: 'created_at',
          order: 'desc',
          isRegistered: true,
          name: searchQuery || undefined,
          email: searchQuery || undefined,
        },
      });
      setMembers(response.data.memberships);
    } catch (error) {
      console.debug(error);
      captureException(error);
      dispatch(showUnknownErrorModal());
    } finally {
      setIsLoadingMembers(false);
    }
  };

  // 登録
  const handleRegister = async () => {
    const subjectIds =
      targetType === 'team'
        ? [team.id]
        : targetType === 'group'
        ? selectedGroups
        : selectedMembers;

    try {
      setIsLoading(true);
      const res = await apiAxios.post(authApiPrefix, {
        subjectIds: subjectIds,
        authorityType: authorityType,
      });
      const createdAuthorities = res.data;

      // t:権限を追加しました
      dispatch(showToast({ message: t('quickPromptManagement:shareModal.messages.authorityAdded'), severity: 'success' }));
      setIsAdding(false);
      setAuthorities([...createdAuthorities, ...authorities]);

      if (isQpSet) {
        dispatch(setIsSharedForQpSet({id: sharedObject.id, isShared: true}));
      } else {
        dispatch(setIsSharedForQp({id: sharedObject.id, isShared: true} ));
      }
    } catch (error) {
      console.debug(error);
      captureException(error);
      dispatch(showUnknownErrorModal());
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      {isAdding ? (
        <>
          <DialogTitle>
            {/* t:新規追加 */}
            {t('common:button.new')}
          </DialogTitle>
          <DialogContent>
            {isAdmin && (
              <FormControl component="fieldset" sx={{ width: '100%' }}>
                {/* t:どの範囲で共有しますか？ */}
                <FormLabel>{t("quickPromptManagement:shareModal.form.targetType")}</FormLabel>
                <RadioGroup
                  row
                  value={targetType}
                  onChange={(e) => setTargetType(e.target.value as 'team' | 'group' | 'member')}
                >
                  {/* t: 特権管理者のみチーム全体への共有が可能です */}
                  <Tooltip title={isSuperAdmin ? "" : t("quickPromptManagement:shareModal.tooltips.onlySuperAdminCanShareToTeam")}>
                    <span>
                      <FormControlLabel
                        value="team"
                        control={<Radio />}
                        // t:チーム
                        label={t("quickPromptManagement:shareModal.radio.team")}
                        disabled={!isSuperAdmin}
                      />
                    </span>
                  </Tooltip>
                  {/* t:グループ */}
                  <FormControlLabel value="group" control={<Radio />} label={t("quickPromptManagement:shareModal.radio.group")} />
                  {/* t:メンバー */}
                  <FormControlLabel value="member" control={<Radio />} label={t("quickPromptManagement:shareModal.radio.member")} />
                </RadioGroup>
              </FormControl>
            )}
            <FormControl sx={{ mt: 2, width: '100%' }}>
              <FormLabel>
                {/* t:どの権限を付与しますか？ */}
                {t("quickPromptManagement:shareModal.form.authority")}
              </FormLabel>
              <Select
                value={authorityType}
                onChange={(e) => setAuthorityType(e.target.value as QpAuthorityType)}
                sx={{marginTop: 1}}
              >
                {isOwner && (
                  // t:オーナー
                  <MenuItem value="owner">{t("quickPromptManagement:shareModal.authorityType.owner")}</MenuItem>
                )}
                {/* t:編集者 */}
                <MenuItem value="editor">{t("quickPromptManagement:shareModal.authorityType.editor")}</MenuItem>
                {/* t:利用者 */}
                <MenuItem value="user">{t("quickPromptManagement:shareModal.authorityType.user")}</MenuItem>
              </Select>
            </FormControl>

            {targetType === 'group' && (
              <FormControl sx={{ mt: 2, width: '100%' }}>
                <FormLabel>
                  {/* t:権限 */}
                  {t("quickPromptManagement:shareModal.form.group")}
                </FormLabel>
                {isLoadingGroups ? (
                  <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box sx={{ maxHeight: 200, overflowY: 'auto', mt: 2 }}>
                    {groups.map((group) => (
                      <FormControlLabel
                        key={group.id}
                        control={
                          <Checkbox
                            checked={selectedGroups.includes(group.id)}
                            onChange={(e) => {
                              const newSelected = e.target.checked
                                ? [...selectedGroups, group.id]
                                : selectedGroups.filter((id) => id !== group.id);
                              setSelectedGroups(newSelected);
                            }}
                          />
                        }
                        label={group.name}
                      />
                    ))}
                  </Box>
                )}
              </FormControl>
            )}

            {targetType === 'member' && (
              <FormControl sx={{ mt: 2, width: '100%' }}>
                <FormLabel>
                  {/* t:権限 */}
                  {t("quickPromptManagement:shareModal.form.member")}
                </FormLabel>
                {/* t:検索 */}
                <TextField
                  placeholder={t("quickPromptManagement:shareModal.form.search")}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  fullWidth
                  sx={{ mt: 2 }}
                />
                {isLoadingMembers ? (
                  <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <List sx={{ maxHeight: 200, overflowY: 'auto', mt: 2}}>
                    {members.map((member) => (
                      <ListItem key={member.id} dense>
                        <ListItemAvatar>
                          {member.user.picture ? (
                            <Avatar src={member.user.picture} />
                          ) : (
                            <Avatar>{
                              member.user.name?.charAt(0) ||
                              member.user.email.charAt(0)
                            }</Avatar>
                          )}
                        </ListItemAvatar>
                        <ListItemText primary={member.user.name || member.user.email} />
                        <Checkbox
                          edge="end"
                          checked={selectedMembers.includes(member.id)}
                          onChange={(e) => {
                            const newSelected = e.target.checked
                              ? [...selectedMembers, member.id]
                              : selectedMembers.filter((id) => id !== member.id);
                            setSelectedMembers(newSelected);
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsAdding(false)}>
              {/* t:戻る */}
              {t("quickPromptManagement:shareModal.buttons.back")}
            </Button>
            <Button
              variant="contained"
              onClick={handleRegister}
              disabled={
                targetType === 'group'
                  ? selectedGroups.length === 0
                  : targetType === 'member'
                  ? selectedMembers.length === 0
                  : false
              }
            >
              {/* t:登録 */}
              {t("common:button.create")}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>
            {/* t:「{{name}}」を共有 */}
            {t('quickPromptManagement:shareModal.title', { name: sharedObject.name })}
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="body2">
                {/* t: この{{type}}にアクセスできるユーザーを管理できます。 */}
                {t('quickPromptManagement:shareModal.description', {
                  type: (
                    isQpSet ?
                    // t: カテゴリ
                    t('quickPromptManagement:shareModal.labels.category') :
                    // t: クイックプロンプト
                    t('quickPromptManagement:shareModal.labels.quickPrompt')
                  )
                })}
              </Typography>
              <Button variant="contained" onClick={() => setIsAdding(true)}>
                {/* t:新規追加 */}
                {t('common:button.newAdd')}
              </Button>
            </Box>
            <Divider />
            <Typography variant="body2" mt={2}>
              {/* t:アクセスできるユーザー */}
              {t('quickPromptManagement:shareModal.labels.accessibleUsers')}
            </Typography>
            {isLoading ? (
              <Box display="flex" justifyContent="center" mt={2}>
                <CircularProgress />
              </Box>
            ) : (
              <List>
                {concatAuthorities.map((authority) => {
                  const isReadOnly = readOnlyAuthorities.some(
                    (readOnlyAuth) => readOnlyAuth.subjectId === authority.subjectId
                  );
                  const isTeamButNotSuperAdmin = authority.subjectType === 'team' && !isSuperAdmin;
                  const isGroupButNotAdmin = authority.subjectType === 'group' && !isAdmin;
                  const isTargetOwnerAndSelfEditor = (
                    authority.authorityType === QpAuthorityType.OWNER && isEditor
                  );

                  // 編集権限がない場合は、disabledにしてtooltipを表示
                  const isNotEditable = (
                    isReadOnly ||
                    isTeamButNotSuperAdmin ||
                    isGroupButNotAdmin ||
                    isTargetOwnerAndSelfEditor
                  );
                  let tooltipTitle = "";
                  if (isNotEditable) {
                    if (isTeamButNotSuperAdmin) {
                      // t:チーム全体の権限は「特権管理者」のみが操作可能です。
                      tooltipTitle = t("quickPromptManagement:shareModal.tooltips.teamAuthoritySuperAdminOnly");
                    } else if (isGroupButNotAdmin) {
                      // t:グループの権限は「管理者」のみが操作可能です。
                      tooltipTitle = t("quickPromptManagement:shareModal.tooltips.groupAuthorityAdminOnly");
                    } else if (isTargetOwnerAndSelfEditor) {
                      // t:編集者はオーナーの権限を操作できません。
                      tooltipTitle = t("quickPromptManagement:shareModal.tooltips.editorCannotChangeOwner");
                    } else if (isReadOnly) {
                      if (
                        !isQpSet &&
                        (sharedObject as QuickPrompt).quickPromptSetId === unclassifiedQpSet?.id &&
                        authority.subjectId === membership?.id
                      ) {
                        // t:未分類の場合、自身の権限は変更できません。
                        tooltipTitle = t("quickPromptManagement:shareModal.tooltips.cannotChangeSelfInUnclassified");
                      } else {
                        // t:カテゴリで設定されてる権限のため、編集できません。
                        tooltipTitle = t("quickPromptManagement:shareModal.tooltips.cannotEditInheritedAuthority");
                      }
                    }
                  }

                  return (
                    <ListItem
                      key={authority.subjectId}
                      sx={{
                        // borderBottom: '1px solid #e0e0e0',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      secondaryAction={
                        <Tooltip title={tooltipTitle}>
                          <span>
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleDeleteAuthority(authority.id)}
                              edge="end"
                              size='small'
                              sx={{ marginRight: "-10px" }}
                              disabled={isNotEditable}
                            >
                              <Delete />
                            </IconButton>
                          </span>
                        </Tooltip>
                      }
                    >
                      <ListItemAvatar>
                        {authority.subjectType === 'membership' && authority.iconUrl ? (
                          <Avatar src={authority.iconUrl} />
                        ) : (
                          <Avatar>
                            {authority.subjectType === 'team'
                              ? 'T'
                              : authority.subjectType === 'group'
                              ? 'G'
                              : ''}
                          </Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText primary={authority.subjectName} />
                      <FormControl variant="standard" sx={{ minWidth: 120 }}>
                        <Tooltip title={tooltipTitle}>
                          <span>
                            <Select
                              value={authority.authorityType}
                              onChange={(e) =>
                                handleAuthorityChange(
                                  authority.id,
                                  e.target.value as QpAuthorityType,
                                )
                              }
                              disabled={isNotEditable}
                            >
                              {/* t:オーナー */}
                              <MenuItem value="owner">{t("quickPromptManagement:shareModal.authorityType.owner")}</MenuItem>
                              {/* t:編集者 */}
                              <MenuItem value="editor">{t("quickPromptManagement:shareModal.authorityType.editor")}</MenuItem>
                              {/* t:利用者 */}
                              <MenuItem value="user">{t("quickPromptManagement:shareModal.authorityType.user")}</MenuItem>
                            </Select>
                          </span>
                        </Tooltip>
                      </FormControl>
                    </ListItem>
                  );
                })}
              </List>
            )}
            {isFailedGetReadOnlyAuthorities && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="caption" color="textSecondary">
                  {/* t:※ あなたの権限では、上記以外の共有メンバーは表示できません。 */}
                  {t('quickPromptManagement:shareModal.labels.failedGetReadOnlyNote')}
                </Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>
              {/* t:閉じる */}
              {t('common:button.close')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default QpShareModal;
