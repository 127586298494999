import { useEffect, useState } from 'react';
import { Box, TextField, Typography, FormControl, InputLabel, Select, MenuItem, Divider, List, ListItem, ListItemIcon, ListItemText, Paper, useTheme, Tooltip } from '@mui/material';
import { QpEditStep1Data } from '../qpEditForm.type';
import IconPicker from '../../generic/containers/IconPicker';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import { fetchQpManagement, fetchQuickPromptSetsIfNeeded } from '../../quickPrompt/quickPrompt.slice';
import { ThreadTitle } from '../../thread/presenters/ThreadPresenter';
import { ChevronRight } from '@mui/icons-material';
import DynamicIcon from '../../generic/containers/DynamicIcon';
// import WorkAizerLogo from '@/assets/images/logo/logo_trim.svg';
import { sortQpSetByQpManagementOrder } from '../../quickPrompt/quickPrompt.utils';
import { QpAuthorityType, QuickPromptSetType } from '../../quickPrompt/quickPrompt.constant';
import ArrowTooltip from '../../generic/containers/ArrowTooltip';
import { useTranslation } from 'react-i18next';

export const NEW_QP_SET = 'NEW-QP-SET';

interface Step1BasicInfoProps {
  data: QpEditStep1Data,
  authorityType?: QpAuthorityType;
  onChangeData: (data: QpEditStep1Data) => void;
  onChangeComplete: (completed: boolean) => void;
  isScreenDownMd: boolean;
  isScreenDownSm: boolean;
}

function QpEditStep1BasicInfoContainer({
  data,
  authorityType,
  onChangeData,
  onChangeComplete,
  isScreenDownMd,
  isScreenDownSm,
}: Step1BasicInfoProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const {
    quickPromptSets,
    qpManagement
  } = useSelector((state: RootState) => state.quickPrompt);
  const theme = useTheme();

  const unclassifiedQpSet = quickPromptSets.find(
    set => set.type === QuickPromptSetType.UNCLASSIFIED
  );
  // 下記の条件を全て満たすものを選択可能なセットとする
  //   - isOfficial=False
  //   - hideSetIdsに含まれない
  //   - type=UNCLASSIFIEDでない
  // またサイドバーの並び順にあわせて並び替える
  const selectableQuickPromptSets = sortQpSetByQpManagementOrder(
    quickPromptSets.filter(set => (
      !set.isOfficial &&
      !qpManagement.hideSetIds.includes(set.id) &&
      set.authorityType != QpAuthorityType.USER &&
      set.type !== QuickPromptSetType.UNCLASSIFIED
    )),
    qpManagement
  );

  const [selectedSetId, setSelectedSetId] = useState<string | undefined>(data.setId || undefined);
  const [newSetName, setNewSetName] = useState(data.newSetName || '');
  const [name, setName] = useState(data.name || '');
  const [icon, setIcon] = useState(data.icon || '');
  const [description, setDescription] = useState(data.description || '');
  const [shortDescription, setShortDescription] = useState(data.shortDescription || '');

  const isNextButtonEnabled = (
    name.trim().length > 0 &&
    name.trim().length <= 100 &&
    (
      (
        !!selectedSetId &&
        selectedSetId != NEW_QP_SET
      ) ||
      (
        selectedSetId == NEW_QP_SET &&
        newSetName.trim().length > 0
      )
    )
  );

  useEffect(() => {
    // quickPromptSets が未取得の場合に取得する
    if (quickPromptSets.length === 0) {
      dispatch(fetchQuickPromptSetsIfNeeded());
      dispatch(fetchQpManagement());
    }
  }, [dispatch, quickPromptSets]);

  useEffect(() => {
    // 変更内容を入力
    const updatedData = {
      setId: selectedSetId,
      newSetName: newSetName,
      name,
      icon,
      description,
      shortDescription,
    };
    onChangeData(updatedData);

    // 入力完了の判定
    onChangeComplete(isNextButtonEnabled);
  }, [
    selectedSetId,
    newSetName,
    name,
    icon,
    description,
    shortDescription
  ]);

  // プレビュー用の情報
  const selectedSet = (
    selectedSetId ?
    selectableQuickPromptSets.find(set => set.id === selectedSetId) :
    (unclassifiedQpSet && selectedSetId === unclassifiedQpSet.id) ?
    unclassifiedQpSet :
    null
  );

  // 選択されたセットIDが有効かどうかを確認
  const isValidSetId = (
    selectedSetId === NEW_QP_SET ||
    (unclassifiedQpSet && selectedSetId === unclassifiedQpSet.id) ||
    selectableQuickPromptSets.some(set => set.id === selectedSetId)
  );

  // オーナー権限以外の場合、カテゴリ選択を無効化したい
  const isCategoryDisabled = !!authorityType && authorityType !== QpAuthorityType.OWNER;

  return (
    <Box
      display="flex"
      flexDirection={isScreenDownMd ? 'column' : 'row'}
      p={2}
      sx={{
        flexGrow: 1,
        overflow: 'auto',
        height: '100%'
      }}
    >
      {/* t:1. 基本情報入力 */}
      {/* 入力欄 */}
      <Box flex={1} pr={isScreenDownMd ? 0 : 1}>
        <Typography variant="h6" gutterBottom>
          {/* t:基本情報 */}
          {isScreenDownSm
            ? t('quickPromptEdit:step1.headingSmall')
            : t('quickPromptEdit:step1.heading')
          }
        </Typography>
        <Box>
          <TextField
            // t:名前
            label={t('quickPromptEdit:settingFields.name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            inputProps={{ maxLength: 50 }}
            fullWidth
            margin="normal"
            // t:クイックプロンプトの名前です。(50文字まで)
            placeholder={t('quickPromptEdit:step1.fields.placeholderName')}
            sx={{ '&::placeholder': { fontSize: '0.875rem' } }}
          />

          <Tooltip
            title={
              isCategoryDisabled ?
              t('quickPromptEdit:step1.ownerPermissionRequired') :
              ""
            } // disabled の時だけ表示
            placement="right"
            disableHoverListener={!isCategoryDisabled}       // disabled の時だけホバーを有効に
          >
            {/* MUI Tooltip は disabled な子要素を直接包むと正しく表示されないため、通常 <span> 等のラッパーで囲みます */}
            <span>
              <FormControl
                fullWidth
                margin="normal"
                required
                disabled={isCategoryDisabled}
              >
                {/* t:カテゴリ選択 */}
                <InputLabel id="quick-prompt-set-select-label">{t('quickPromptEdit:step1.fields.categorySelect')}</InputLabel>
                <Select
                  labelId="quick-prompt-set-select-label"
                  id="quick-prompt-set-select"
                  value={isValidSetId ? selectedSetId || '' : ''}
                  label={t('quickPromptEdit:step1.fields.categorySelect')}
                  onChange={(e) => {
                    if (isCategoryDisabled) return;

                    const value = e.target.value || undefined;
                    setSelectedSetId(value);
                  }}
                >
                  {selectableQuickPromptSets.map(set => (
                    <MenuItem key={set.id} value={set.id}>
                      {set.name}
                    </MenuItem>
                  ))}
                  {unclassifiedQpSet &&
                      <MenuItem
                        key={unclassifiedQpSet.id}
                        value={unclassifiedQpSet.id}>
                        <Tooltip
                          placement="right"
                          // t:カテゴリを設定しないこともできます。その場合、作成したクイックプロンプトは「未分類」カテゴリに配置されます。
                          title={t("quickPromptEdit:step1.fields.categoryUnselectedTooltip")}
                        >
                          {/* t:カテゴリを設定しない */}
                          <em>{t("quickPromptEdit:step1.fields.categoryUnselected")}</em>
                        </Tooltip>
                      </MenuItem>
                  }
                  <MenuItem key={NEW_QP_SET} value={NEW_QP_SET}>
                    <em>{t("quickPromptEdit:step1.fields.newCategory")}</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </span>
          </Tooltip>

          {
            selectedSetId === NEW_QP_SET ? <>
              <TextField
                // t:新しいカテゴリの名前
                label={t("quickPromptEdit:step1.fields.newCategoryName")}
                value={newSetName}
                onChange={(e) => setNewSetName(e.target.value)}
                required
                fullWidth
                margin="normal"
                // t:(例) 要約・翻訳
                placeholder={t("quickPromptEdit:step1.fields.placeholderNewCategory")}
              />
              <Typography variant="caption" color="textSecondary">
                {/* t:新しいカテゴリの追加の場合は、カテゴリ名を入力してください。 */}
                {t("quickPromptEdit:step1.memoNewCategory")}
              </Typography>
            </> : (
              <Typography variant="caption" color="textSecondary">
                {/* t:編集権限がないカテゴリは選択できません。 */}
                {t("quickPromptEdit:step1.memoUnclassified")}
              </Typography>
            )
          }

          <TextField
            // t:説明文
            label={t("quickPromptEdit:settingFields.description")}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            inputProps={{ maxLength: 2000 }}
            fullWidth
            multiline
            minRows={3}
            maxRows={9}
            margin="normal"
            // t:入力フォーム上部に表示する説明文です。\n用途や使い方等をご記載ください。\n(2000文字まで)
            placeholder={t("quickPromptEdit:step1.fields.placeholderDescription")}
            sx={{ 'textarea::placeholder': { fontSize: '0.875rem' } }}
          />

          <Box margin="normal" mb={1}>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {/* t:アイコン選択 */}
              {t("quickPromptEdit:step1.fields.iconLabel")}
            </Typography>
            <IconPicker
              value={icon}
              onChange={(iconName) => setIcon(iconName)}
            />
          </Box>

          <TextField
            // t:サイドバーに表示される説明文
            label={t("quickPromptEdit:step1.fields.shortDescriptionLabel")}
            value={shortDescription}
            onChange={(e) => setShortDescription(e.target.value)}
            inputProps={{ maxLength: 150 }}
            fullWidth
            multiline
            minRows={3}
            maxRows={9}
            // t:左サイドバーにて、マウスカーソルを上に乗せた時に表示される説明です。(150文字まで)
            placeholder={t("quickPromptEdit:step1.fields.placeholderShortDescription")}
            margin="normal"
            sx={{ 'textarea::placeholder': { fontSize: '0.875rem' } }}
          />
        </Box>
      </Box>

      {/* プレビュー */}
      {!isScreenDownMd && (
        <Box display="flex" flex={1} ml={3} borderLeft={`1px solid ${theme.palette.divider}`}>
          <Box p={2} ml={2} flex={1} style={{ backgroundColor: theme.palette.background.default }}>
            <Typography variant="h6" gutterBottom>
              {/* t:プレビュー */}
              {t("quickPromptEdit:step1.previewTitle")}
            </Typography>
            <Box>
              <Typography variant="subtitle1" color="textSecondary">
                {/* t:サイドバーでの表示 */}
                {t("quickPromptEdit:step1.previewSideBarTitle")}
              </Typography>
              <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <List sx={{ display: 'inline-block', bgcolor: 'background.paper' }}>
                  <ListItem dense sx={{ pl: 0 }}>
                    <ListItemIcon sx={{ minWidth: 27 }}>
                      <ChevronRight />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        (!selectedSet && !newSetName) ? (
                          <i>
                            {
                              selectedSetId == NEW_QP_SET
                                // t:新しいカテゴリ名を入力してください
                                ? t("quickPromptEdit:step1.previewEmptyCategoryNew")
                                : selectedSetId == unclassifiedQpSet?.id
                                // t:未分類
                                ? t("quickPromptEdit:step1.previewEmptyCategoryUnclassified")
                                // t:カテゴリを選択してください
                                : t("quickPromptEdit:step1.previewEmptyCategory")
                            }
                          </i>
                        ) : (
                          <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                            {selectedSet ? selectedSet.name : newSetName}
                          </Box>
                        )
                      }
                      sx={{ m: 0, '& .MuiListItemText-primary': { fontSize: '0.875rem' } }}
                    />
                  </ListItem>
                  <ArrowTooltip
                    title={shortDescription}
                    placement="right"
                    disableInteractive
                    open={true}
                    // 時間差なく表示されるようにする (ステップ切替時に違和感あるので)
                    TransitionProps={{ timeout: 0 }}
                    PopperProps={{
                      // モーダルの上に重ならないようにする
                      style: { zIndex: 1 }
                    }}
                  >
                    <ListItem dense sx={{ pl: 5 }}>
                      <ListItemIcon sx={{ minWidth: 35 }}>
                        <DynamicIcon iconName={icon} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ m: 0, flexGrow: 1, '& .MuiListItemText-primary': { fontSize: '0.875rem' } }}
                        primary={
                          name ? name : <i>{t("quickPromptEdit:step1.previewEmptyName")}</i>
                        }
                      />
                    </ListItem>
                  </ArrowTooltip>
                </List>
              </Paper>

              <Typography variant="subtitle1" color="textSecondary">
                {/* t:入力フォームの上部 */}
                {t("quickPromptEdit:step1.previewFormTitle")}
              </Typography>
              <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <Box>
                  {
                    name
                      ? <ThreadTitle variant="h6">{name}</ThreadTitle>
                      : <ThreadTitle variant="body2"><i>{t("quickPromptEdit:step1.previewEmptyName")}</i></ThreadTitle>
                  }
                  <Divider sx={{ my: 0.5 }} />
                  <Box>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      py={1}
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      {description}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default QpEditStep1BasicInfoContainer;
