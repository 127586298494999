import { Language } from "../setting/setting.constant";
import { QpAuthorityType, QuickPromptSetType } from "./quickPrompt.constant";

export type QuickPromptSet = {
  id: string;
  type: QuickPromptSetType,
  officialCode?: string;
  name: string;
  icon?: string;
  language?: Language,
  isShared: boolean,
  isOfficial: boolean,
  baseOrder?: number,
  createdAt: string;
  updatedAt: string;
  authorityType: QpAuthorityType;
}

export type QuickPrompt = {
  id: string;
  quickPromptSetId: string;
  officialCode?: string;
  isOfficial: boolean,
  isShared: boolean,
  name: string;
  description?: string;
  shortName?: string;
  shortDescription?: string;
  baseOrder?: number,
  icon?: string;
  createdAt: string;
  updatedAt: string;
  authorityType: QpAuthorityType;
};

export type QuickPromptVersion = {
  id: string;
  properties?: ReqQpProperty[],
  formSchema: object;
  uiSchema: object;
  promptTemplate: string;
  createdAt: string;
};

export type QuickPromptWithVersion = QuickPrompt & {
  latestVersion: QuickPromptVersion;
  targetVersion?: QuickPromptVersion;
  latestInput?: object;
};

export type QuickPromptWithOptionalVersion = QuickPrompt | QuickPromptWithVersion;


export type QuickPromptWithSetAndVersion = QuickPromptWithVersion & {
  quickPromptSet: QuickPromptSet;
}

export type QuickPromptSetWithQpList = QuickPromptSet & {
  quickPrompts: QuickPromptWithOptionalVersion[];
}

export type QuickPromptVersionWithQP = QuickPromptVersion & {
  quickPrompt?: QuickPrompt;
}

export type RecommendedQp = {
  qp: QuickPrompt;
  color: string;
  description: string;
}

export function getAllQuickPrompts(quickPromptSets: QuickPromptSetWithQpList[]): QuickPromptWithOptionalVersion[] {
  return quickPromptSets.flatMap(set => set.quickPrompts);
}

export type QpManagement = {
  hideSetIds: string[];
  hideQpIds: string[];
  orderedSetIds: string[];
  orderedQpIds: string[];
}


// ----------------------------------------------
// 以下、QPの各プロパティについて
// ----------------------------------------------

export enum QpPropertyType {
  TEXT_FIELD = 'textField',
  TEXT_AREA = 'textArea',
  CHECK_BOX = 'checkBox',
  RADIO_BUTTON = 'radioButton',
  SELECT_BOX = 'selectBox',
  SINGLE_CHECK_BOX = 'singleCheckBox',
  TEXT_AREA_WITH_TOOLBAR = "textAreaWithToolbar",
  SELECT_AI_MODELS = "selectAiModels",
  TOGGLE_ADVANCED_SETTING = "toggleAdvancedSetting",
}

export interface ReqQpProperty {
  label: string;
  key: string;
  isAdvancedMode: boolean;
  type: QpPropertyType;
  detail: ReqQpPropertyDetail;
}

export interface ReqQpPropertyDetail {
  textField?: QpTextFieldDetail;
  textArea?: QpTextAreaDetail;
  textAreaWithToolbar?: QpTextAreaWithToolbarDetail;
  selectBox?: QpSelectBoxDetail;
  checkBox?: QpCheckBoxDetail;
  radioButton?: QpRadioButtonDetail;
  singleCheckBox?: QpSingleCheckBoxDetail;
  selectAiModels?: QpSelectAiModelsDetail;
  toggleAdvancedSetting?: QpToggleAdvancedSettingDetail;
}

export interface QpInputPropertyDetailBase {
  isRequired: boolean;
  helpText: string;
}

export interface QpChoiceOptions {
  value: string;
  label: string;
  helpText: string;
  isDefault: boolean;
  prompt?: string;
}

export interface QpChoicePropertyDetailBase extends QpInputPropertyDetailBase {
  options: QpChoiceOptions[];
}

export interface QpTextFieldDetail extends QpInputPropertyDetailBase {
  maxLength: number;
  placeholder: string;
  defaultValue: string;
}

export interface QpTextAreaDetail extends QpTextFieldDetail {
  rows: number;
}

export interface QpTextAreaWithToolbarDetail extends QpTextAreaDetail {}


export interface QpSelectBoxDetail extends QpChoicePropertyDetailBase {}

export interface QpRadioButtonDetail extends QpChoicePropertyDetailBase {
  isInline: boolean;
}

export interface QpCheckBoxDetail extends QpChoicePropertyDetailBase {
  isInline: boolean;
}

export interface QpSingleCheckBoxDetail extends QpInputPropertyDetailBase {
  defaultValue: boolean;
  prompt?: string;
}

export interface QpSelectAiModelsDetail extends QpInputPropertyDetailBase {
  defaultModels?: string[];
}

export interface QpToggleAdvancedSettingDetail {
}
