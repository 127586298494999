import { QpTextFieldDetail } from "@/components/features/quickPrompt/quickPrompt.type";
import { TextField } from "@mui/material";
import QpInputPropertySettingFieldsBase from "./QpInputPropertySettingFieldsBase";
import { ReqQpPropertyEdit } from "../../qpEditForm.type";
import { useTranslation } from "react-i18next";

interface Props {
  property: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  detail: QpTextFieldDetail;
  onChange: (data: QpTextFieldDetail) => void;
  inputRows?: number;
}

function QpTextFieldSettingFields({
  property,
  allProperties,
  detail,
  onChange,
  inputRows = 1,
}: Props) {
  const { t } = useTranslation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    onChange({
      ...detail,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <>
      <QpInputPropertySettingFieldsBase
        property={property}
        allProperties={allProperties}
        detail={detail}
        onChange={(baseData) =>
          onChange({
            ...detail,
            ...baseData,
          })
        }
      />

      {property.isAdvancedMode && (
        <TextField
          // t:プレースホルダー
          label={t("quickPromptEdit:settingFields.placeholderLabel")}
          name="placeholder"
          value={detail.placeholder || ""}
          onChange={handleInputChange}
          fullWidth
          multiline
          minRows={inputRows}
          maxRows={inputRows > 1 ? 9 : 1}
          margin="normal"
        />
      )}

      {/* <TextField
        label="初期値"
        name="defaultValue"
        value={detail.defaultValue || ''}
        onChange={handleInputChange}
        fullWidth
        multiline={inputRows > 1 ? true : false}
        rows={inputRows}
        margin="normal"
      /> */}
    </>
  );
}

export default QpTextFieldSettingFields;
