import { QpSelectAiModelsDetail } from "@/components/features/quickPrompt/quickPrompt.type";
import { SelectAiModelCodesPresenter } from "@/components/features/quickPrompt/components/SelectAiModelCodes";
import { ReqQpPropertyEdit } from "../../qpEditForm.type";
import { TextField } from "@mui/material";
// t:i18n
import { useTranslation } from "react-i18next";

interface Props {
  property: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  detail: QpSelectAiModelsDetail;
  onChange: (data: QpSelectAiModelsDetail) => void;
}

function QpSelectAiModelsSettingFields({
  property,
  detail,
  onChange,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      {/* t:最初に選ばれるモデル */}
      <SelectAiModelCodesPresenter
        id={property.id}
        label={t("quickPromptEdit:settingFields.selectDefaultModels")}
        onChange={(codes: string[]) => {
          onChange({
            ...detail,
            defaultModels: codes,
          });
        }}
        value={detail.defaultModels || []}
        fullWidth
      />
      {property.isAdvancedMode && (
        <TextField
          // t:説明文
          label={t("quickPromptEdit:settingFields.description")}
          name="helpText"
          value={detail.helpText || ""}
          onChange={(e) =>
            onChange({
              ...detail,
              helpText: e.target.value,
            })
          }
          fullWidth
          margin="normal"
        />
      )}
    </>
  );
}

export default QpSelectAiModelsSettingFields;
