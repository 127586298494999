import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { QpCheckBoxDetail, QpChoiceOptions, QpPropertyType, QpRadioButtonDetail, QpSelectBoxDetail, QpTextAreaDetail, QpTextAreaWithToolbarDetail, QpTextFieldDetail } from '../../quickPrompt/quickPrompt.type';
import QpSingleCheckboxSettingFields from '../components/settingFields/QpSingleCheckboxSettingFields';
import QpTextFieldSettingFields from '../components/settingFields/QpTextFieldSettingFields';
import QpTextAreaSettingFields from '../components/settingFields/QpTextAreaSettingFields';
import QpCheckBoxSettingFields from '../components/settingFields/QpCheckBoxSettingFields';
import QpRadioButtonSettingFields from '../components/settingFields/QpRadioButtonSettingFields';
import QpSelectBoxSettingFields from '../components/settingFields/QpSelectBoxSettingFields';
import { ReqQpPropertyEdit } from '../qpEditForm.type';
import QpSelectAiModelsSettingFields from '../components/settingFields/QpSelectAiModelsSettingFields';
import QpToggleAdvanceSettingFields from '../components/settingFields/QpToggleAdvanceSettingFields';
import { generatePropertyKey } from '../qpEditForm.utils';
import QpTextAreaWithToolbarSettingFields from '../components/settingFields/QpTextAreaWithToolbarSettingFields';
import { useTranslation } from 'react-i18next';

interface SettingsModalProps {
  open: boolean;
  defaultData: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  onClose: () => void;
  onSave: (data: ReqQpPropertyEdit) => void;
}

function QpEditStep2PropertySettingsModalContainer({
  open,
  defaultData,
  allProperties,
  onClose,
  onSave
}: SettingsModalProps) {
  const { t } = useTranslation();
  const [data, setData] = useState<ReqQpPropertyEdit>(defaultData);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // バリデーション通過後に実行される

    // 項目名からキーを作成
    const key = generatePropertyKey(data.label);

    // キーが他の項目と重複している場合はエラーを表示し、重複している項目名を表示
    const duplicateProperty = allProperties.find(p => p.key === key && p.id !== data.id);
    if (duplicateProperty) {
      alert(
        data.label === duplicateProperty.label
          ? (
            // t:項目名が他の項目と重複しています。\n各項目には違う名前を設定するようお願いいたします。
            t("quickPromptEdit:step2.error.duplicateSameLabel")
          ) : (
            // t:項目名が他の項目と類似しています。\n処理の都合、記号や空白以外の部分が同じ項目名は設定できません。\n以下のどちらかの項目名を変更するようお願いいたします。\n\n現在の項目: {{currentLabel}}\n類似の項目: {{duplicateLabel}}
            t("quickPromptEdit:step2.error.duplicateSimilarLabel", {
              currentLabel: data.label,
              duplicateLabel: duplicateProperty.label
            })
          )
      );
      return;
    }

    onSave({ ...data, key });
    onClose();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleToggleAdvancedMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.target.checked;

    // オフにしようとしているとき、エキスパート用項目を使ってる場合はアラート
    if (!nextValue) {
      let isAdvanceSettingsUsing = false;
      if (data.detail.textField) {
        if (data.detail.textField.helpText ||
            data.detail.textField.placeholder) {
          isAdvanceSettingsUsing = true;
        }
      }
      if (data.detail.textArea) {
        if (data.detail.textArea.helpText ||
            data.detail.textArea.placeholder ||
            data.detail.textArea.defaultValue) {
          isAdvanceSettingsUsing = true;
        }
      }
      let options: QpChoiceOptions[] = [];
      if (data.detail.checkBox) {
        options = data.detail.checkBox.options;
        if (data.detail.checkBox.isInline ||
            data.detail.checkBox.helpText) {
          isAdvanceSettingsUsing = true;
        }
      }
      if (data.detail.radioButton) {
        options = data.detail.radioButton.options;
        if (data.detail.radioButton.isInline ||
            data.detail.radioButton.helpText) {
          isAdvanceSettingsUsing = true;
        }
      }
      if (data.detail.selectBox) {
        options = data.detail.selectBox.options;
        if (data.detail.selectBox.helpText) {
          isAdvanceSettingsUsing = true;
        }
      }
      options.forEach(option => {
        if (option.helpText || option.prompt) {
          isAdvanceSettingsUsing = true;
        }
      });
      if (data.detail.singleCheckBox) {
        if (data.detail.singleCheckBox.helpText ||
            data.detail.singleCheckBox.prompt ||
            data.detail.singleCheckBox.defaultValue) {
          isAdvanceSettingsUsing = true;
        }
      }
      if (data.detail.selectAiModels) {
        if (data.detail.selectAiModels.helpText) {
          isAdvanceSettingsUsing = true;
        }
      }

      if (isAdvanceSettingsUsing) {
        // t:エキスパートモード専用の項目が入力されているため、オフにできません。
        alert(t("quickPromptEdit:step2.error.expertItemExists"));
        // スイッチを切り替えないようにする
        return;
      }
    }

    // 条件を満たしていなければ通常通り反映
    setData((prev) => ({
      ...prev,
      isAdvancedMode: nextValue,
    }));
  };

  const renderSettingsForm = () => {
    if (data.type == QpPropertyType.TEXT_FIELD && data.detail.textField) {
      return (
        <QpTextFieldSettingFields
          property={data}
          allProperties={allProperties}
          detail={data.detail.textField}
          onChange={(textDetail: QpTextFieldDetail) => {
            setData(prev => ({
              ...prev,
              detail: {
                textField: textDetail,
              },
            }));
          }}
        />
      );
    }
    if (data.type == QpPropertyType.TEXT_AREA && data.detail.textArea) {
      return (
        <QpTextAreaSettingFields
          property={data}
          allProperties={allProperties}
          detail={data.detail.textArea}
          onChange={(textDetail: QpTextAreaDetail) => {
            setData(prev => ({
              ...prev,
              detail: {
                textArea: textDetail,
              },
            }));
          }}
        />
      );
    }
    if (data.type == QpPropertyType.TEXT_AREA_WITH_TOOLBAR && data.detail.textAreaWithToolbar) {
      return (
        <QpTextAreaWithToolbarSettingFields
          property={data}
          allProperties={allProperties}
          detail={data.detail.textAreaWithToolbar}
          onChange={(textDetail: QpTextAreaWithToolbarDetail) => {
            setData(prev => ({
              ...prev,
              detail: {
                textAreaWithToolbar: textDetail,
              },
            }));
          }}
        />
      );
    }
    if (data.type == QpPropertyType.CHECK_BOX && data.detail.checkBox) {
      return (
        <QpCheckBoxSettingFields
          property={data}
          allProperties={allProperties}
          detail={data.detail.checkBox}
          onChange={(choiceDetail: QpCheckBoxDetail) => {
            setData(prev => ({
              ...prev,
              detail: {
                checkBox: choiceDetail,
              },
            }));
          }}
        />
      );
    }
    if (data.type == QpPropertyType.RADIO_BUTTON && data.detail.radioButton) {
      return (
        <QpRadioButtonSettingFields
          property={data}
          allProperties={allProperties}
          detail={data.detail.radioButton}
          onChange={(choiceDetail: QpRadioButtonDetail) => {
            setData(prev => ({
              ...prev,
              detail: {
                radioButton: choiceDetail,
              },
            }));
          }}
        />
      );
    }
    if (data.type == QpPropertyType.SELECT_BOX && data.detail.selectBox) {
      return (
        <QpSelectBoxSettingFields
          property={data}
          allProperties={allProperties}
          detail={data.detail.selectBox}
          onChange={(textDetail: QpSelectBoxDetail) => {
            setData(prev => ({
              ...prev,
              detail: {
                selectBox: textDetail,
              },
            }));
          }}
        />
      );
    }
    if (data.type == QpPropertyType.SINGLE_CHECK_BOX && data.detail.singleCheckBox) {
      return (
        <QpSingleCheckboxSettingFields
          property={data}
          allProperties={allProperties}
          detail={data.detail.singleCheckBox}
          onChange={(textDetail) => {
            setData(prev => ({
              ...prev,
              detail: {
                singleCheckBox: textDetail,
              },
            }));
          }}
        />
      );
    }
    if (data.type == QpPropertyType.SELECT_AI_MODELS && data.detail.selectAiModels) {
      return (
        <QpSelectAiModelsSettingFields
          property={data}
          allProperties={allProperties}
          detail={data.detail.selectAiModels}
          onChange={(textDetail) => {
            setData(prev => ({
              ...prev,
              detail: {
                selectAiModels: textDetail,
              },
            }));
          }}
        />
      );
    }
    if (data.type == QpPropertyType.TOGGLE_ADVANCED_SETTING && data.detail.toggleAdvancedSetting) {
      return (
        <QpToggleAdvanceSettingFields
          // ※ 必要に応じて他のロジックを追加
        />
      );
    }
    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      {/* ▼ タイトルとトグルを同じ行に配置 */}
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="h6">
            {/* t:項目設定 */}
            {t("quickPromptEdit:step2.modal.title")}
          </Typography>
          <FormControlLabel
            labelPlacement="start"
            label={
              <Typography
                variant="body2"
                fontWeight={ data.isAdvancedMode ? "bold" : "normal" }
                color={ data.isAdvancedMode ? "primary" : "textPrimary" }
              >
                {/* t:エキスパートモード */}
                {t("quickPromptEdit:step2.modal.expertModeLabel")}
              </Typography>
            }
            control={
              <Switch
                color="primary"
                checked={!!data.isAdvancedMode}
                onChange={handleToggleAdvancedMode}
              />
            }
          />
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box
          id="step2-property-settings-form"
          component="form"
          onSubmit={handleSubmit}
        >
          <TextField
            // t:項目名
            label={t("quickPromptEdit:step2.modal.fieldLabel")}
            name="label"
            value={data.label || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
            inputProps={{ maxLength: 100 }}
            helperText="100文字まで"
          />
          {renderSettingsForm()}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {/* t:キャンセル => common.jsonを使用 */}
          {t("common:button.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          form="step2-property-settings-form"
          type="submit"
        >
          {/* t:保存 => common.jsonを使用 */}
          {t("common:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default QpEditStep2PropertySettingsModalContainer;
