import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { ReqQpPropertyEdit } from "../../qpEditForm.type";
import { isAdvanceSettingProperty } from "../../qpEditForm.utils";
import { useTranslation } from "react-i18next";

interface RequiredCheckboxProps {
  property: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string
  name?: string
}

function QpRequiredCheckbox({
  property,
  allProperties,
  checked,
  onChange,
  label,
  name = "isRequired"
}: RequiredCheckboxProps) {
  const { t } = useTranslation();
  const isAdvanceSetting = isAdvanceSettingProperty(property, allProperties);

  return (
    <>
      {/* t:詳細設定の場合は必須には出来ません */}
      <Tooltip title={isAdvanceSetting ? t("quickPromptEdit:settingFields.cannotRequiredForAdvanced") : ""}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={onChange}
              name={name}
              disabled={isAdvanceSetting}
            />
          }
          // t:必須
          label={label ? label : t("common:validation.required")}
        />
      </Tooltip>
    </>
  );
}

export default QpRequiredCheckbox;
