import React, { useState, useEffect, useCallback } from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  IconButton,
  Box,
  ListItem,
  Tooltip,
} from '@mui/material';
import {
  QuickPrompt,
  QuickPromptSetWithQpList,
} from '../quickPrompt.type';
import { Link as RouterLink } from 'react-router-dom';
import WorkAizerLogo from '@/assets/images/logo/logo_trim.svg';
import QpListQpSetMenuContainer from '../containers/QpListQpSetMenuContainer';
import QpListQpMenuContainer from '../containers/QpListQpMenuContainer';

import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  DragOverlay,
} from '@dnd-kit/core';
import {
  useSortable,
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DynamicIcon from '../../generic/containers/DynamicIcon';
import { ChevronRight, ExpandMore, Group, MoreVert } from '@mui/icons-material';
import { FAVORITE_QP_SET_ID, QuickPromptSetType } from '../quickPrompt.constant';
import { useTranslation } from 'react-i18next';
import ArrowTooltip from '../../generic/containers/ArrowTooltip';

const MenuList = styled(List)`
  flex-grow: 1;
  overflow-y: hidden;
  padding-right: 17px; // スクロールバーの平均的な幅
  box-sizing: content-box; // paddingをwidthに含めない

  &::-webkit-scrollbar {
    width: 8px; // マウスオーバー時にはスクロールバーの幅を8pxに設定
  }

  &:hover {
    overflow-y: auto;
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.5); // マウスオーバー時のスクロールバーの色と不透明度を設定
    }
  }
`;

interface QpListPresenterProps {
  quickPromptSets: QuickPromptSetWithQpList[];
  expandedSets: { [key: string]: boolean };
  bookmarkedQuickPromptIds: string[];
  handleToggleSet: (setId: string) => void;
  handleAddBookmark: (quickPromptId: string) => void;
  handleRemoveBookmark: (quickPromptId: string) => void;
  handleUpdateSetOrder: (newOrder: QuickPromptSetWithQpList[]) => void;
}

const QpListPresenter: React.FC<QpListPresenterProps> = ({
  quickPromptSets: sortedQuickPromptSets,
  expandedSets,
  handleToggleSet,
  bookmarkedQuickPromptIds,
  handleAddBookmark,
  handleRemoveBookmark,
  handleUpdateSetOrder,
}) => {
  // 一度useStateにセットして使わないと、ドラッグ&ドロップでのソートの挙動がおかしくなる
  const [quickPromptSetsOrder, setQuickPromptSetsOrder] = useState(sortedQuickPromptSets);
  const [activeId, setActiveId] = useState<string | null>(null);
  const activeItem = activeId ? quickPromptSetsOrder.find((item) => item.id === activeId) : null;
  const { t } = useTranslation();

  useEffect(() => {
    setQuickPromptSetsOrder(sortedQuickPromptSets);
  }, [sortedQuickPromptSets]);

  const [menuSelectedQpSet, setMenuSelectedQpSet] = useState<QuickPromptSetWithQpList | null>(null);
  const [menuSelectedQp, setMenuSelectedQp] = useState<QuickPrompt | null>(null);

  const [anchorPosition, setAnchorPosition] = useState<{ mouseX: number; mouseY: number } | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = useCallback(
    (
      event: React.MouseEvent<HTMLElement>,
      qpSet: QuickPromptSetWithQpList,
      qp?: QuickPrompt
    ) => {
      event.preventDefault();
      event.stopPropagation();

      setMenuOpen(true);
      setAnchorPosition({
        mouseX: event.clientX + window.scrollX,
        mouseY: event.clientY + window.scrollY,
      });

      setMenuSelectedQpSet(qpSet);
      setMenuSelectedQp(qp ?? null);
    },
    []
  );

  const handleMenuClose = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuOpen(false);
  }, []);

  const handleDragStart = useCallback((event: any) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = useCallback(
    (event: any) => {
      const { active, over } = event;

      if (active.id !== over?.id && over) {
        const oldIndex = quickPromptSetsOrder.findIndex((item) => item.id === active.id);
        const newIndex = quickPromptSetsOrder.findIndex((item) => item.id === over.id);
        const newOrder = arrayMove(quickPromptSetsOrder, oldIndex, newIndex);

        setQuickPromptSetsOrder(newOrder);
        handleUpdateSetOrder(newOrder); // 並び順の更新を親コンポーネントに伝える
      }

      setActiveId(null);
    },
    [quickPromptSetsOrder, handleUpdateSetOrder]
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 150, // ドラッグの開始を150ミリ秒遅延
        tolerance: 5, // ドラッグの開始を5ピクセルの移動距離で許可
      },
    })
  );

  const getSetName = (qpSet: QuickPromptSetWithQpList) => {
    if (qpSet.type == QuickPromptSetType.SHARED) {
      return t("quickPrompt:qpList.shared");
    }
    if (qpSet.type == QuickPromptSetType.UNCLASSIFIED) {
      return t("quickPrompt:qpList.unclassified");
    }
    return qpSet.name;
  };

  const SharedIcon = <Group sx={{ fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.5)', ml: 0.5 }} />;
  const isSpecialQpSet = (qpSet: QuickPromptSetWithQpList) => {
    return (
      qpSet.type === QuickPromptSetType.SHARED ||
      qpSet.type === QuickPromptSetType.UNCLASSIFIED ||
      qpSet.id === FAVORITE_QP_SET_ID
    );
  }

  const SortableSetItem = React.memo(
    (
      { set, isDragOverlay = false }:
      { set: QuickPromptSetWithQpList; isDragOverlay?: boolean; }
    ) => {
      const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
      } = useSortable({ id: set.id });

      const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 2 : 1,
        opacity: isDragging && !isDragOverlay ? 0 : 1, // ドラッグ中の要素を非表示にする
      };

      return (
        <div
          ref={setNodeRef}
          style={style}
          {...(isDragOverlay ? {} : { ...attributes, ...listeners })}
        >
          <ListItemButton
            dense
            sx={{
              ml: 0,
              mr: 0,
              pl: 1.5,
              pr: 1.2,
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.04)',
                '& .MuiIconButton-root': { visibility: 'visible' },
              },
            }}
            onClick={() => handleToggleSet(set.id)}
          >
            <ListItemIcon sx={{ minWidth: 31 }}>
              {expandedSets[set.id] ? (
                <ExpandMore sx={{ mr: 1 }} />
              ) : (
                <ChevronRight sx={{ mr: 1 }} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                  {
                    isSpecialQpSet(set) ?
                    <em>{getSetName(set)}</em> :
                    getSetName(set)
                  }
                  {set.isOfficial && (
                    <Box component="span" className="OfficialQpIcon" sx={{ ml: 1 }}>
                      <img src={WorkAizerLogo} alt="WorkAizer Logo" width={15} height={15} />
                    </Box>
                  )}
                  {set.isShared && SharedIcon}
                </Box>
              }
              sx={{ m: 0, '& .MuiListItemText-primary': { fontSize: '0.875rem' } }}
            />
            {
              isSpecialQpSet(set) ?
              <Tooltip
                // t: 「{{name}}」は特殊なカテゴリです。編集したり非表示にしたりはできません。
                title={t(
                  "quickPrompt:qpList.notices.specialQpSetCannotEdit",
                  { name: getSetName(set) }
                )}
                placement="right">
                <span>
                  <IconButton
                    size="small"
                    disabled
                    sx={{ visibility: menuOpen && menuSelectedQpSet === set && !menuSelectedQp ? 'visible' : 'hidden' }}
                  >
                    <MoreVert fontSize="inherit" />
                  </IconButton>
                </span>
              </Tooltip> :
              <IconButton
                size="small"
                onClick={(e) => handleMenuClick(e, set)}
                sx={{ visibility: menuOpen && menuSelectedQpSet === set && !menuSelectedQp ? 'visible' : 'hidden' }}
              >
                <MoreVert fontSize="inherit" />
              </IconButton>
            }
          </ListItemButton>
          {expandedSets[set.id] && (
            <List component="div" disablePadding sx={{ marginBottom: 0.5 }}>
              {
                set.quickPrompts.length > 0 ?
                set.quickPrompts.map((qp) => (
                  <ArrowTooltip
                    key={qp.id}
                    title={qp.shortDescription}
                    placement="right"
                    disableInteractive
                  >
                    <ListItemButton
                      dense
                      sx={{
                        pl: 5,
                        pr: 1.2,
                        display: 'flex',
                        alignItems: 'center',
                        '&:hover .MuiIconButton-root': { visibility: 'visible' },
                      }}
                      component={RouterLink}
                      to={`/quick-prompts/${qp.id}/threads/new`}
                    >
                      <ListItemIcon sx={{ minWidth: 35 }}>
                        <DynamicIcon iconName={qp.icon} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ m: 0, flexGrow: 1, '& .MuiListItemText-primary': { fontSize: '0.875rem' } }}
                        primary={
                          <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                            {qp.name}
                            {qp.isShared && SharedIcon}
                          </Box>
                        }
                      />
                      <IconButton
                        size="small"
                        onClick={(e) => handleMenuClick(e, set, qp)}
                        sx={{ visibility: menuOpen && menuSelectedQpSet === set && menuSelectedQp === qp ? 'visible' : 'hidden' }}
                      >
                        <MoreVert fontSize="inherit" />
                      </IconButton>
                    </ListItemButton>
                  </ArrowTooltip>
                )) :
                <ListItem dense>
                  <ListItemText
                    // t: プロンプトがありません
                    primary={t("quickPrompt:qpList.messages.noPrompt")}
                    sx={{
                      pl: 3,
                      pr: 1.5,
                      '& .MuiListItemText-primary': {
                        fontSize: '0.75rem'
                      }
                    }}
                  />
                </ListItem>
              }
            </List>
          )}
        </div>
      );
    }
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <MenuList sx={{ pr: 0, pl: 0 }}>
        <SortableContext
          items={quickPromptSetsOrder.map((set) => set.id)}
          strategy={verticalListSortingStrategy}
        >
          {quickPromptSetsOrder.map((set) => (
            <SortableSetItem key={set.id} set={set} />
          ))}
        </SortableContext>
      </MenuList>

      <DragOverlay>
        {activeItem ? <SortableSetItem set={activeItem} isDragOverlay /> : null}
      </DragOverlay>

      {/* メニュー */}
      <QpListQpSetMenuContainer
        anchorPosition={anchorPosition}
        open={menuOpen && Boolean(menuSelectedQpSet && !menuSelectedQp)}
        quickPromptSet={menuSelectedQpSet!}
        handleMenuClose={handleMenuClose}
      />
      <QpListQpMenuContainer
        anchorPosition={anchorPosition}
        open={menuOpen && Boolean(menuSelectedQpSet && menuSelectedQp)}
        quickPromptSet={menuSelectedQpSet!}
        quickPrompt={menuSelectedQp!}
        bookmarkedQuickPromptIds={bookmarkedQuickPromptIds}
        handleMenuClose={handleMenuClose}
        handleAddBookmark={handleAddBookmark}
        handleRemoveBookmark={handleRemoveBookmark}
      />
    </DndContext>
  );
};

export default QpListPresenter;
