import { useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { QpEditStep3Data, ReqQpPropertyEdit } from '../qpEditForm.type';
import { useEditor, EditorContent } from '@tiptap/react';
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Placeholder from "@tiptap/extension-placeholder";
import { Node, mergeAttributes } from '@tiptap/core';
import QpEditStep3TemplateHelper from './QpEditStep3TemplateHelper';
import styled from '@emotion/styled';
import theme from '@/theme';
import History from '@tiptap/extension-history';
import { useTranslation } from 'react-i18next';

interface Step3PromptProps {
  data: QpEditStep3Data;
  properties: ReqQpPropertyEdit[];
  onChangeData: (data: QpEditStep3Data) => void;
  onChangeComplete: (completed: boolean) => void;
  isScreenDownMd: boolean;
  isScreenDownSm: boolean;
}

const EditorBoxStyle = styled(Box)`
  flex-grow: 1;
  border: 1px solid ${theme.palette.grey[300]};
  border-radius: ${theme.shape.borderRadius}px;
  overflow-y: auto;
  height: 100%;

  width: 0; // これを入れないと1行の文字数に応じて横幅が広がる問題が発生する

  & > div {
    height: 100%;
  }

  & .ProseMirror {
    display: block;
    padding: 8px;
    font-size: 14px;
    line-height: 1.6;
    background-color: #fff;
    color: #333;
    min-height: 100%;
    max-width: 100%;
    /* 折り返し関連のプロパティを整理 */
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: break-all;
    line-break: anywhere;

    // pタグはmaginなし
    & p {
      margin: 0;
    }

    // 変数ノードの見た目調整
    & .ProseMirror-variable-node {
      background-color: ${theme.palette.primary.light};
      border: 1px solid ${theme.palette.primary.light};
      color: white;
      line-height: 2; // 高さがあるので前後の行と近くなりすぎないようにする
      padding-top: ${theme.spacing(0.5)};
      padding-bottom: ${theme.spacing(0.5)};
      padding-left: ${theme.spacing(0.5)};
      padding-right: 0; // カーソル位置調整の都合、要素内のスペースで制御
      margin: 0 ${theme.spacing(0.5)};
      border-radius: ${theme.shape.borderRadius * 1.5}px;
      font-family: ${theme.typography.fontFamily};
      font-size: 0.875rem;
      font-weight: bold;
      letter-spacing: 0.02em;
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
      user-select: none;
      cursor: default;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      &:hover {
        background-color: ${theme.palette.primary.dark};
        transform: translateY(-1px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
      &.ProseMirror-selectednode {
        outline: 1px solid ${theme.palette.primary.light};
        outline-offset: 2px;
        background-color: ${theme.palette.primary.dark};
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      }
    }

    // プレイスホルダーの表示
    & p.is-editor-empty:first-of-type::before {
      content: attr(data-placeholder);
      float: left;
      color: ${theme.palette.text.disabled};
      pointer-events: none;
      height: 0;
    }
  }
`;

function QpEditStep3PromptTemplateContainer({
  data,
  properties,
  onChangeData,
  onChangeComplete,
  isScreenDownMd,
  isScreenDownSm,
}: Step3PromptProps) {

  const { t } = useTranslation();

  // VariableNode定義
  const VariableNode = Node.create({
    name: 'variable',
    group: 'inline',
    inline: true,
    atom: true,
    selectable: true,
    addAttributes() {
      return {
        name: {
          default: '',
        },
        'data-label': {
          default: '',
        },
      };
    },
    parseHTML() {
      return [
        {
          tag: 'span[data-variable]',
        },
      ];
    },
    renderHTML({ node, HTMLAttributes }) {
      return ['span', mergeAttributes(HTMLAttributes, {
        'data-variable': node.attrs.name,
        contenteditable: 'false',
        class: 'ProseMirror-variable-node',
      }), ` ${node.attrs['data-label']} ` ];
    },
  });

  // jinja2 -> Tiptap用HTML変換関数
  const convertJinjaToTiptapHTML = (template: string) => {
    // {{ prompt("xxx") }} を検出する正規表現
    const promptRegex = /{{\s*prompt\(\s*["']([^"']+)["']\s*\)\s*}}/g;

    // マッチする部分を <span data-variable="xxx">xxx</span> に変換
    const replaced = template.replace(promptRegex, (_, p1) => {
      const label = properties.find(p => p.key === p1)?.label || p1;
      return `<span name="${p1}" data-label="${label}" data-variable="${p1}" contenteditable="false" class="ProseMirror-variable-node">${label}</span>`;
    });

    // 改行を <p> でラップする簡易的な処理
    const lines = replaced.split(/\r?\n/).map(line => {
      return `<p>${line || ''}</p>`;
    }).join('');

    return lines;
  };

  // Tiptap JSON -> Jinja2 テンプレートへの逆変換関数
  const convertTiptapJSONToJinja = (json: any): string => {
    // jsonは { type: 'doc', content: [{ type: 'paragraph', content: [...] }, ...] } という形
    if (!json || !json.content) return '';

    const lines = json.content.map((block: any) => {
      if (block.type === 'paragraph' && block.content) {
        // 段落内のコンテンツをテキスト化
        return block.content.map((node: any) => {
          if (node.type === 'variable' && node.attrs && node.attrs.name) {
            // variableノードは {{ prompt("varName") }} に戻す
            return `{{ prompt("${node.attrs.name}") }}`;
          } else if (node.type === 'text') {
            return node.text || '';
          }
          return '';
        }).join('');
      } else if (block.type === 'paragraph') {
        // 空のparagraphなら空行
        return '';
      }
      return '';
    });

    // 段落を改行で結合
    return lines.join('\n');
  };

  // 初期化時に Jinja2テンプレートをVariableNodeに変換したHTMLを生成
  const initialHTML = useMemo(() => {
    if (data.promptTemplate) {
      return convertJinjaToTiptapHTML(data.promptTemplate);
    } else {
      return '';
    }
  }, [data.promptTemplate]);

  // t:こちらに生成AIに送信したいプロンプトを入力してください。
  //   ユーザーが入力・選択した値を挿入するには、「テンプレートヘルパー」をお使いください。
  const placeholderText = t("quickPromptEdit:step3.placeholderText");

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Placeholder.configure({
        placeholder: placeholderText,
      }),
      VariableNode,
      // undo/redoを使えるようにする
      History,
    ],
    content: initialHTML,
  });

  useEffect(() => {
    if (editor) {
      const update = () => {
        const json = editor.getJSON();
        const jinjaTemplate = convertTiptapJSONToJinja(json);
        onChangeData({ ...data, promptTemplate: jinjaTemplate });
        const textContent = editor.getText();
        onChangeComplete(textContent.trim().length > 0);
      };
      editor.on('update', update);
      return () => {
        editor.off('update', update);
      };
    }
  }, [editor, onChangeData, onChangeComplete, data]);

  const helper = <QpEditStep3TemplateHelper properties={properties} editor={editor} />;

  return (
    <Box
      display="flex"
      flexDirection={isScreenDownMd ? 'column' : 'row'}
      gap={3}
      p={2}
      sx={{
        height: '100%',
        minHeight: 0,
      }}
    >
      {/* 左側エディタエリア */}
      <Box
        display="flex"
        flexDirection="column"
        pr={isScreenDownMd ? 0 : 1}
        flexGrow={1}
        minWidth={0}
        sx={{ minHeight: 0 }}
      >
        <Typography variant="h6" gutterBottom>
          {/* t:3. プロンプト作成/プロンプトテンプレート */}
          {isScreenDownSm
            ? t("quickPromptEdit:step3.headingSmall")
            : t("quickPromptEdit:step3.headingLarge")}
        </Typography>
        <Box display="flex" flexGrow={1} position="relative" sx={{ minHeight: 0 }}>
          <EditorBoxStyle>
            <EditorContent editor={editor} />
          </EditorBoxStyle>
        </Box>
      </Box>

      {/* 右側テンプレートヘルパー */}
      {
        isScreenDownMd
          ? <Box mt={2}>{helper}</Box>
          : helper
      }
    </Box>
  );
}

export default QpEditStep3PromptTemplateContainer;
