import { useState } from 'react';
import { Box, IconButton, Typography, Tooltip } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReqQpPropertyEdit } from '../qpEditForm.type';
import { DragHandle, Edit, Delete } from '@mui/icons-material';
import QpEditStep2PropertySettingsModalContainer from './QpEditStep2PropertySettingsModalContainer';
import { QpPropertyType } from '../../quickPrompt/quickPrompt.type';
import { useTranslation } from 'react-i18next';

interface Props {
  property: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  onDelete: () => void;
  onUpdate: (property: ReqQpPropertyEdit) => void;
}

function QpEditStep2ItemRow({ property, allProperties, onDelete, onUpdate }: Props) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: property.id,
  });
  const { t } = useTranslation();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [openSettings, setOpenSettings] = useState(false);

  const isToggleAdvanceSetting = property.type == QpPropertyType.TOGGLE_ADVANCED_SETTING;

  return (
    <>
      <Box
        ref={setNodeRef}
        style={style}
        display="flex"
        alignItems="center"
        p={1}
        borderBottom="1px solid #ccc"
      >
        <Box
          {...attributes}
          {...listeners}
          sx={{ cursor: isDragging ? 'grabbing' : 'grab', display: 'flex', alignItems: 'center' }}
        >
          <DragHandle />
        </Box>
        <Box display="flex" flexDirection="column" sx={{ flexGrow: 1, ml: 2 }}>
          <Typography variant="body1">
            {property.label}
          </Typography>
          {
            property.type === QpPropertyType.TOGGLE_ADVANCED_SETTING &&
            <Typography variant="caption" color="textSecondary">
              {/*
                t:以下の項目は{{label}}をONにした場合のみ表示されます。
              */}
              {t("quickPromptEdit:step2.toggleAdvanceSettingDescription", {
                label: property.label
              })}
            </Typography>
          }
        </Box>
        {
          !isToggleAdvanceSetting &&
          <Tooltip
            // t:編集
            title={t("common:button.edit")}
          >
            <IconButton onClick={() => setOpenSettings(true)}>
              <Edit />
            </IconButton>
          </Tooltip>
        }
        <Tooltip
          // t:削除
          title={t("common:button.delete")}
        >
          <IconButton
            onClick={() => {
              // t:この項目を削除しますか？
              if (window.confirm(t("quickPromptEdit:step2.deleteItemConfirm"))) {
                onDelete();
              }
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
      {openSettings && (
        <QpEditStep2PropertySettingsModalContainer
          open={openSettings}
          defaultData={property}
          allProperties={allProperties}
          onClose={() => setOpenSettings(false)}
          onSave={(updatedProperty) => {
            onUpdate(updatedProperty);
            setOpenSettings(false);
          }}
        />
      )}
    </>
  );
}

export default QpEditStep2ItemRow;
